import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { Alert, Button, Checkbox, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SET_LOGIN_STATUS, useAuth } from "../../hooks/auth/AuthContext";
import { setTokenCookie } from "../../shared/helpers/set-token-cookies.helper";
import PhoneInput from "../../shared/widgets/phone-input/PhoneInput";
import { GET_REFERRAL_INFO, PHONE_SIGNIN, PHONE_SIGNIN_VERIFY_CODE, PHONE_SIGNUP, PHONE_SIGNUP_VERIFY_CODE } from "./auth.gql";
import CodeRequestButton from "./CodeRequestButton";
import { client } from "../../apollo-clients";
import { useLocation } from "react-router-dom";
import { getStrMemberId } from "../../shared/helpers/mask-label.helpers";
import { setGeneralCookie } from "../../shared/helpers/set-general-cookie.helper";

interface Props {
    isSignUp?: boolean
    next?: () => void;
    referralID?: string;
    fromReferralLink: boolean;
    maintenanceData: any;
}

const LoginSMSForm = ({ isSignUp = false, next, referralID = '', fromReferralLink, maintenanceData }: Props) => {
    //don't store anything during login  
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const { data: referralData, error: referralError } = useQuery(GET_REFERRAL_INFO, { variables: { referralID: query.get('id') ? query.get('id') : referralID }, client, fetchPolicy: "no-cache", });
    const [phoneSignIn, { data: signInData, error: signInError }] = useMutation(PHONE_SIGNIN, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [phoneSignUp, { data: signUpData, error: signUpError }] = useMutation(PHONE_SIGNUP, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [phoneSignInVerifyCode, { data: signInVerifyCodeData, loading: signInVerifyCodeLoading, error: signInVerifyCodeError }]
        = useMutation(PHONE_SIGNIN_VERIFY_CODE, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [phoneSignUpVerifyCode, { data: signUpVerifyCodeData, loading: signUpVerifyCodeLoading, error: signUpVerifyCodeError }]
        = useMutation(PHONE_SIGNUP_VERIFY_CODE, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const { authDispatch } = useAuth();
    const intl = useIntl();
    const [form] = Form.useForm();
    const [phone, setPhone] = useState<string>();
    const [countryId, setCountryId] = useState<string>('MY');
    const [error, setError] = useState<string>();
    const [referrerName, setReferrerName] = useState<string>();
    let isComposition = false;
    const [startCountdown, setStartCountdown] = useState<boolean>(false);
    const urlAccName = process.env.REACT_APP_STORAGE_ACCOUNTNAME ? process.env.REACT_APP_STORAGE_ACCOUNTNAME : '';

    useEffect(() => {
        if (signUpVerifyCodeData && signUpVerifyCodeData?.phoneSignUpVerifyCode === null) {
            setError('AUTH_ERROR_INVALID_CODE');
        }

        if ((signUpVerifyCodeData && signUpVerifyCodeData.phoneSignUpVerifyCode) ||
            (signInVerifyCodeData && signInVerifyCodeData.phoneSignInVerifyCode)) {
            //set Login tokens
            const { a, r, sid } = isSignUp ? signUpVerifyCodeData.phoneSignUpVerifyCode :
                signInVerifyCodeData.phoneSignInVerifyCode;
            setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || 'scf-at', a);
            setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || 'scf-rt', r);
            setTokenCookie('scf-sid', sid);
            setGeneralCookie(`c-cooldown-start-false`, '59', 59);
            if (signInVerifyCodeData && signInVerifyCodeData.phoneSignInVerifyCode) {
                authDispatch({ type: SET_LOGIN_STATUS, payload: true });
            }
            if (next)
                next();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpVerifyCodeData, signInVerifyCodeData])

    useEffect(() => {
        if (signUpError && signUpError.message?.indexOf('ACCOUNT_EXISTED') > -1) {
            setError('AUTH_ERROR_ACCOUNT_EXISTED');
        } else if (signUpData) {
            setStartCountdown(true);
        }

        if (signInError) {
            if (signInError.message?.indexOf('INVALID_LOGIN') > -1) {
                setError('AUTH_ERROR_INVALID_LOGIN');
            } else if (signInError.message?.indexOf('ACCOUNT_NOT_EXISTED') > -1) {
                setError('AUTH_ERROR_ACCOUNT_NOT_EXISTED');
            } else if (signInError.message?.indexOf('FREQUENT_ACTION') > -1) {
                setError('AUTH_ERROR_INVALID_LOGIN');
            } else {
                setError('AUTH_ERROR_INVALID_LOGIN');
            }
        }
    }, [signUpError, signInError, signUpData, signInData])

    useEffect(() => {
        if (fromReferralLink) {
            if (referralError) {
                if (referralError.message?.indexOf('ACCOUNT_NOT_EXISTED') > -1)
                    setError('AUTH_ERROR_REFERRAL_NOT_EXISTED');
                else if (referralError.message?.indexOf('REFERRAL_ID_REQUIRED') > -1)
                    setError('AUTH_REFERRAL_ID_REQUIRED');
            } else {
                if (referralData && referralData?.getReferralInfo) {
                    setReferrerName(referralData?.getReferralInfo?.name);
                }
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referralData, referralError])

    useEffect(() => {
        if ((signUpVerifyCodeError && signUpVerifyCodeError.message?.indexOf('INVALID_LOGIN') > -1) ||
            (signInVerifyCodeError && signInVerifyCodeError.message?.indexOf('INVALID_LOGIN') > -1)) {
            setError('AUTH_ERROR_INVALID_CODE');
        }
    }, [signUpVerifyCodeError, signInVerifyCodeError])

    const requestStatus = () => {
        if (maintenanceData && maintenanceData.isMaintenance) {
            setError(maintenanceData.en);
        } else {
            setError(undefined);
            if (isSignUp) {
                let dob = form.getFieldValue('dob');
                console.log('dob', dob);
                phoneSignUp({
                    variables: {
                        phoneNumber: phone,
                        countryId,
                        referralMemberID: query.get('id') ? getStrMemberId(query.get('id')) : referralID
                    }
                })
            } else {
                phoneSignIn({
                    variables: {
                        phoneNumber: phone
                    }
                })
            }
        }
    }

    const onValuesChange = ({ phoneNumber }: any) => {
        if (phoneNumber) {
            setPhone(phoneNumber);
        }
    }

    const onFinish = (values: any) => {
        if (maintenanceData && maintenanceData.isMaintenance) {
            setError(maintenanceData.en);
        } else {
            if (isSignUp) {
                phoneSignUpVerifyCode({
                    variables: {
                        phoneNumber: phone,
                        name: values.name,
                        code: values.code,
                        referralMemberID: query.get('id') ? getStrMemberId(query.get('id')) : referralID
                    }
                })
            } else {
                phoneSignInVerifyCode({
                    variables: {
                        phoneNumber: phone,
                        code: values.code
                    }
                })
            }
        }
    };

    const selectCountryId = (countryId: string) => {
        setCountryId(countryId);
    }

    const onRedirectClick = () => {
        let url = process.env.REACT_APP_REDIRECT_SITE_URL;
        if (url) {
            window.location.assign(url);
        }
    }

    return (<Form
        name="form"
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
    >
        {error && <Alert
            className="auth-error"
            message={maintenanceData && maintenanceData.isMaintenance ? error : <FormattedMessage id={error} />}
            type="error"
            showIcon
        />}
        {fromReferralLink &&
            <Alert
                className="auth-error"
                message={<FormattedMessage id={'REGISTER_REFERRAL_INFO'} values={{
                    referralID: getStrMemberId(query.get('id')),
                    name: referrerName
                }} />}
                type="info"
                showIcon
            />
        }
        {isSignUp && <Form.Item
            name="name"
            rules={[
                { required: true, message: intl.formatMessage({ id: 'LOGIN_NAME_REQUIRED' }) }]}>
            <Input maxLength={255} placeholder={intl.formatMessage({ id: 'GENERAL_NAME' })}
                onChange={(e) => {
                    if (!isComposition) {
                        form.setFieldsValue({ name: e.target.value.toUpperCase() });
                    }
                }}
                onCompositionStart={(e: any) => isComposition = true}
                onCompositionEnd={(e: any) => isComposition = false} />
        </Form.Item>}
        <div className='redirect-container' onClick={onRedirectClick}>
            <div className='redirect-name'>
                <FormattedMessage id='REDIRECT_SITE' />
            </div>
        </div>
        <Form.Item
            name="phoneNumber"
            rules={[
                { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                { min: 8, message: intl.formatMessage({ id: 'LOGIN_USERNAME_VALIDATE' }) }]}>
            <PhoneInput selectCountryId={selectCountryId} />
        </Form.Item>
        <div className="sms-same-row-container">
            <Form.Item
                name="code"
                rules={[
                    { required: true, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE_REQUIRED' }) },
                    { min: 6, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }
                ]}>
                <Input maxLength={6} placeholder={intl.formatMessage({ id: 'PHONE_VERIFICATION_INPUT_PLACEHOLDER' })} />
            </Form.Item>
            <CodeRequestButton isSignUp={isSignUp}
                phoneNumber={phone}
                hasError={signUpError || signInError ? true : false}
                requestStatus={requestStatus}
                startCountdown={startCountdown}
            />
        </div>
        {isSignUp && <Form.Item name="agreeTnC" valuePropName="checked" rules={[
            {
                required: true, transform: value => (value || undefined),
                type: 'boolean', message: intl.formatMessage({ id: 'AUTH_TNC_ENFORCE' })
            }
        ]}>
            <Checkbox><FormattedMessage id="AUTH_TNC" values={{
                0: <a href={`https://${urlAccName}/tnc/Risk%20Disclosure_V2_Herzen.pdf?c=${moment().unix()}`}
                    target="_blank" rel="noopener noreferrer">Risk Disclosure</a>,
                1: <a href={`https://${urlAccName}/tnc/AML%20Policy_V2_Herzen.pdf?c=${moment().unix()}`}
                    target="_blank" rel="noopener noreferrer">AML Policy</a>,
                2: <a href={`https://${urlAccName}/tnc/Privacy%20Policy_V2_Herzen.pdf?c=${moment().unix()}`}
                    target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            }} /></Checkbox>
        </Form.Item>}
        <Form.Item className="auth-button">
            <Button block type="primary" htmlType="submit" loading={signInVerifyCodeLoading || signUpVerifyCodeLoading}>
                <FormattedMessage id={isSignUp ? 'GENERAL_NEXT' : 'AUTH_BUTTON_LOGIN'} />
            </Button>
        </Form.Item>
    </Form>)
}

export default LoginSMSForm;