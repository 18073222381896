import React, { useEffect, useState } from 'react';
import { Layout, Menu, Avatar, Badge, Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { LinkOutlined } from '@ant-design/icons';
import { DisconnectOutlined } from '@ant-design/icons';
import transferIcon from "../../../assets/images/icons/transfer_white.svg";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  LockOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import './GeneralHeader.less';
import { SET_LOGIN_STATUS, CLEAR_CURRENT_USER, useAuth } from '../../../hooks/auth/AuthContext';
import LanguageSwitcher from '../../widgets/language-switcher/LanguageSwitcher';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/client';
import { LOGOUT } from '../../../pages/auth/auth.gql';
import { clearAllTokens } from '../../helpers/set-token-cookies.helper';
import { REDIRECT_TO_APP, useLayout } from '../../../hooks/layout/LayoutContext';
const { Header } = Layout;

interface Props {
  collapsed: boolean;
  toggleSideNav?: () => void;
};

const GeneralHeader = ({ collapsed, toggleSideNav }: Props) => {
  const { authState, authDispatch } = useAuth();
  const { layoutState, layoutDispatch } = useLayout();
  const name = authState.userProfile && authState.userProfile?.name ?
    authState.userProfile?.name : '';
  const [logout, { data, error }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });

  useEffect(() => {
    if ((data && data.logout) || error) {
      clearAllTokens();
      authDispatch({ type: CLEAR_CURRENT_USER });
      authDispatch({ type: SET_LOGIN_STATUS, payload: false });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const onLogout = () => {
    logout();
  }

  const getLinkButton = () => {
    const isAppConnected = layoutState.redirectAppDialog.isLinkedPayment ? layoutState.redirectAppDialog.isLinkedPayment : false;
    if (isAppConnected) {
      return <div className="notification-panel" style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <LinkOutlined className="notification"
          onClick={() => {
            layoutDispatch({ type: REDIRECT_TO_APP, payload: { ...layoutState.redirectAppDialog, isRedirect: true, type: 'login' } });
            return false;
          }} 
        />
        <Link to={'/member/payment-transfer'}>
          <div className="notification">
            <img width={26} height={26} style={{paddingLeft: 2}} src={transferIcon} alt={transferIcon}></img>
          </div>
        </Link>
      </div>
    }
    return <div className="notification-panel">
      <DisconnectOutlined className="notification" 
        onClick={() => {
          layoutDispatch({ type: REDIRECT_TO_APP, payload: { ...layoutState.redirectAppDialog, isRedirect: true, type: 'register' } });
          return false;
        }} 
      />
    </div>
  }

  const menu = (
    <Menu className="inner-menu-profile">
      <Menu.Item key="0" icon={<LockOutlined />}>
        <Link to="/member/account/my-profile"><FormattedMessage id="NAV_MY_PROFILE" /></Link>
      </Menu.Item>
      <Menu.Item key="1" icon={<LogoutOutlined />} onClick={onLogout}>
        <FormattedMessage id="NAV_LOGOUT" />
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="site-layout-header" style={{ padding: 0 }}>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: toggleSideNav,
      })}
      <div className="header-actions">
        <LanguageSwitcher />
        <Link to="/member/notification">
          <div className="notification-panel">
            <Badge dot={authState.unreadNotification ? true : false}>
              <BellOutlined className="notification" />
            </Badge>
          </div>
        </Link>
        {/* {getLinkButton()} */}
        <div className="profile-menu-background">
          <Dropdown overlay={menu} className="profile-menu" trigger={['hover', 'click']}>
            <a className="ant-dropdown-link" href="/#">
              {authState?.userProfile?.photoUrl ?
                <Avatar className="avatar" size={40} src={authState?.userProfile?.photoUrl} /> :
                <Avatar className="avatar" size={40} icon={<UserOutlined />} />}
              <span className="profile-menu-title">{name}</span>
            </a>
          </Dropdown>
        </div>
      </div>
    </Header>
  )
}

export default GeneralHeader;