import { ApolloError, useMutation } from "@apollo/client";
import { Alert, Checkbox, Form, Input, Modal, Select } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useNotification from "../../../../hooks/layout/useNotification";
import { GET_CODE } from "../../../account/my-profile/my-profile.gql";
import { VerificationType } from "../../../account/my-profile/VerificationType.enum";
import CodeRequestButton from "../../../account/my-profile/CodeRequestButton";
import { setGeneralCookie } from "../../../../shared/helpers/set-general-cookie.helper";
import { useAuth } from "../../../../hooks/auth/AuthContext";
import { useLayout } from "../../../../hooks/layout/LayoutContext";

interface Props {
    modalVisible: { visible: boolean; setVisible: Dispatch<SetStateAction<boolean>> };
    title?: string;
    submitOTP: { callFunction: any; callData: any; callError: ApolloError | undefined };
    displayMsg: { title: string | undefined, submitBtnTitle: string | undefined, attachedText: string | undefined, id: string }
    tncDocument: any;
    insertAgreedTNC: { callFunction: any, callError: ApolloError | undefined }
}

const VerifyEmailDialog = ({ title, modalVisible, submitOTP, displayMsg, tncDocument, insertAgreedTNC }: Props) => {
    const { authState } = useAuth();
    const intl = useIntl();
    const [form] = Form.useForm();
    const [error, setError] = useState<string>();
    const [success, setSucess] = useState<string>();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [requestOTPCode, { data: requestOTPCodeData, error: requestOTPCodeError }] = useMutation(GET_CODE, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [requestedCode, setRequestedCode] = useState(false);
    const { layoutState } = useLayout();

    useEffect(() => {
        if (submitOTP.callError) {
            setErrorNotification(intl.formatMessage({ id: "VERIFY_EMAIL_FAIL" }));
        } else if (submitOTP.callData && requestedCode) {
            if (submitOTP.callData.registerSuccessVerifyEmail.status === "SUCCESS") {
                _insertAgreedTNC();
                setSuccessNotification(intl.formatMessage({ id: "VERIFY_EMAIL_SUCCESS" }));
                setGeneralCookie(`c-cooldown-start-${VerificationType.EMAIL}`, '59', 59);
                modalVisible.setVisible(false);
                form.resetFields();
            } else {
                if (submitOTP.callData.registerSuccessVerifyEmail.status === "INVALID_CREDENTIAL") {
                    setErrorNotification(intl.formatMessage({ id: "INVALID_PIN" }));
                } else {
                    setErrorNotification(intl.formatMessage({ id: submitOTP.callData.registerSuccessVerifyEmail.status + "_" + VerificationType.EMAIL }));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitOTP.callData, requestedCode]);

    useEffect(() => {
        if (requestOTPCodeData) {
            if (requestOTPCodeData?.userVerification && requestOTPCodeData?.userVerification.status === 'SUCCESS') {
                setSucess(`OTP_EMAIL_SUCCESS`);
                setError('');
            } else {
                setSucess('');
                setError(`OTP_EMAIL_FAILED`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestOTPCodeData]);

    const handleOk = () => {
        if (authState.userProfile?.emailVerified) {
            form.validateFields()
                .then((values) => {
                    _insertAgreedTNC();
                    modalVisible.setVisible(false);
                    form.resetFields();
                })
                .catch((info) => {
                    console.log("Validate Failed:", info);
                });
        } else {
            if (requestedCode) {
                form.validateFields()
                    .then(async (values) => {
                        await submitOTP.callFunction({
                            variables: {
                                verificationCode: values.otp,
                                email: values.email,
                                preferredEmailLanguage: values.preferredEmailLanguage
                            },
                        });
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            } else {
                setErrorNotification(
                    intl.formatMessage({
                        id: "INVALID_VERIFY_RECORD_EMAIL",
                    })
                );
            }
        }
    };

    const requestStatus = () => {
        let email = form.getFieldValue('email');
        setRequestedCode(true);
        requestOTPCode({
            variables: {
                verificationType: VerificationType.EMAIL,
                email,
                language: form.getFieldValue('preferredEmailLanguage')
            },
        });
    };

    const _insertAgreedTNC = async () => {
        await insertAgreedTNC.callFunction({
            variables: {
                tncId: displayMsg.id,
                language: layoutState.locale
            }
        });
    }

    return (
        <>
            <Modal
                destroyOnClose={true}
                closable={false}
                title={title}
                visible={modalVisible.visible}
                onOk={handleOk}
                okText={intl.formatMessage({ id: "SUBMIT" })}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                {(error || success) && <Alert
                    className="auth-error"
                    message={<FormattedMessage id={error ? error : success} />}
                    type={error ? "error" : "success"}
                    showIcon
                />}
                {
                    displayMsg.attachedText &&
                    <div className="tnc-frame"
                        dangerouslySetInnerHTML={{
                            __html: displayMsg.attachedText!
                        }}>
                    </div>
                }
                {
                    authState.userProfile?.emailVerified ? null : <p><FormattedMessage id={"VERIFY_EMAIL_MSG"} /></p>
                }
                <Form form={form} initialValues={{ preferredEmailLanguage: layoutState.locale }}>
                    {authState.userProfile?.emailVerified ? null :
                        <>
                            <Form.Item name="email" label={<><FormattedMessage id="MY_PROFILE_EMAIL" /></>} required={false} className="need-require"
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'MY_PROFILE_EMAIL_REQUIRE' }) },
                                    { type: "email", message: intl.formatMessage({ id: 'MY_PROFILE_EMAIL_TYPE_INVALID' }) },
                                ]}>
                                <Input placeholder={intl.formatMessage({ id: 'MY_PROFILE_EMAIL' })} className="verify-email" />
                            </Form.Item>
                            <Form.Item name="preferredEmailLanguage" label={<><FormattedMessage id="MY_PROFILE_PREFERRED_EMAIL_LANGUAGE" /></>}>
                                <Select>
                                    <Select.Option value="en">
                                        English
                                    </Select.Option>
                                    <Select.Option value="zh-CN">
                                        简体中文
                                    </Select.Option>
                                    <Select.Option value="zh-Hant">
                                        繁體中文
                                    </Select.Option>
                                    <Select.Option value="th">
                                        ไทย
                                    </Select.Option>
                                    <Select.Option value="ms">
                                        Bahasa Melayu
                                    </Select.Option>
                                    {/* <Select.Option value="vn">
                                        Tiếng Việt
                                    </Select.Option> */}
                                </Select>
                            </Form.Item>
                            <div className="sms-same-row-container">
                                <Form.Item
                                    name="otp"
                                    required
                                    rules={[{ required: true, message: intl.formatMessage({ id: "CODE_REQUIRE" }) }]}
                                >
                                    <Input
                                        maxLength={6}
                                        placeholder={intl.formatMessage({
                                            id: `EMAIL_VERIFICATION_INPUT_PLACEHOLDER`
                                        })}
                                    />
                                </Form.Item>
                                <CodeRequestButton
                                    type={VerificationType.EMAIL}
                                    hasError={requestOTPCodeError ? true : false}
                                    requestStatus={requestStatus}
                                    isPinMatched={true}
                                />
                            </div>
                        </>
                    }
                    <Form.Item className='tnc-checkbox' name="thirdPartyTNC" valuePropName="checked" rules={[
                        {
                            required: true, transform: value => (value || undefined),
                            type: 'boolean', message: intl.formatMessage({ id: 'AUTH_TNC_ENFORCE' })
                        }
                    ]}>
                        <Checkbox><FormattedMessage id={`THIRD_PARTY_TNC_DOC`} values={{
                            0: tncDocument ? <a href={tncDocument[0].documentUrl}
                                target="_blank" rel="noopener noreferrer"><FormattedMessage id={tncDocument[0]?.documentCategory} /></a> : ''
                        }} /></Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default VerifyEmailDialog;
