
import './Auth.less';
import { Alert, Button, Form, Input, Modal, Radio } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import cuid from "cuid";
import { setGeneralCookie } from "../../shared/helpers/set-general-cookie.helper";
import PhoneInput from '../../shared/widgets/phone-input/PhoneInput';
import { useMutation, useQuery } from '@apollo/client';
import { CAPTCHA_CODE, SIGN_IN } from './auth.gql';
import { SET_LOGIN_STATUS, useAuth } from '../../hooks/auth/AuthContext';
import { setTokenCookie } from '../../shared/helpers/set-token-cookies.helper';
import { useHistory } from 'react-router-dom';
import { GET_CONFIGURATIONS_CONTACTUS } from '../../shared/components/side-menu/SideMenu.gql';
import { Vertify } from '@alex_xu/react-slider-vertify';
import Captcha from './Captcha';
interface Props {
    maintenanceData: any;
}
const LoginPasswordForm = ({ maintenanceData }: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const [signIn, { data, loading, error: signInError }] = useMutation(SIGN_IN, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [getCaptchaCode, { data: getCaptchaCodeData }] = useMutation(CAPTCHA_CODE, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const { data: data1 } = useQuery(GET_CONFIGURATIONS_CONTACTUS, {
        fetchPolicy: "cache-and-network",
    });
    const [error, setError] = useState<string>();
    const [captchaError, setCaptchaError] = useState<string>();
    const { authDispatch } = useAuth();
    const [selectMemberId, setSelectMemberId] = useState(false);
    const [form] = Form.useForm();
    const [primaPhone, setPrimaPhone] = useState<string>();
    const [loginFailCounter, setLoginFailCounter] = useState<number>(0);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const memberIdPrefix = process.env.REACT_APP_MEMBERID_PREFIX ? process.env.REACT_APP_MEMBERID_PREFIX : '';
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [sessionId, setSessionId] = useState<string>('sessionId');
    const [captcha, setCaptcha] = useState<string>('8888');
    useEffect(() => {
        if (data && data.signIn) {
            setIsModalVisible(false);
            if (data.signIn.loginFailCounter) {
                setLoginFailCounter(data.signIn.loginFailCounter);
                setError('AUTH_ERROR_INVALID_LOGIN_ATTEMPTS_REMAINING');
            } else {
                const { a, r, sid } = data.signIn;
                setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || 'scf-at', a);
                setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || 'scf-rt', r);
                setTokenCookie('scf-sid', sid);
                authDispatch({ type: SET_LOGIN_STATUS, payload: true });
                setGeneralCookie(`c-main-account`, 'true', 1800);
                history.push("/member");
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])
    
    useEffect(() => {
        if (signInError) {
            setIsModalVisible(false);
            if (signInError.message?.indexOf('CODE_NOT_MATCH') > -1) {
                setError('INVALID_CAPTCHA');
            } else if (signInError.message?.indexOf('INVALID_CREDENTIAL') > -1) {
                setError('INVALID_MEMBER_PREFIX');
            } else if (signInError.message?.indexOf('INVALID_LOGIN') > -1) {
                setError('AUTH_ERROR_INVALID_LOGIN');
            } else if (signInError.message?.indexOf('INVALID_PHONE_LOGIN') > -1) {
                setError('AUTH_ERROR_INVALID_LOGIN_PHONE');
            } else if (signInError.message?.indexOf('ACCOUNT_LOCKED') > -1) {
                setError('AUTH_ERROR_ACCOUNT_LOCKED');
            } else if (signInError.message?.indexOf('ACCOUNT_SUSPENDED') > -1) {
                setError('AUTH_ERROR_ACCOUNT_SUSPENDED');
            } else {
                setError('GENERAL_ERROR');
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInError])
    useEffect(() => {
        if (data1 && data1?.configurationsContactUs) {
            const phone = data1?.configurationsContactUs.filter((val: any) => val.id === "primaPhone");
            setPrimaPhone(phone[0].value);
            generateCaptcha();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data1]);
    useEffect(() => {
        if (getCaptchaCodeData && getCaptchaCodeData.captchaCode) {
            if (getCaptchaCodeData.captchaCode.status === 'SUCCESS') {
                setCaptcha(getCaptchaCodeData.captchaCode.a);
            } else {
                setError('GENERAL_ERROR');
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCaptchaCodeData])
    
    const onFinish = (values: any) => {
        if (maintenanceData && maintenanceData.isMaintenance) {
            setError(maintenanceData.en);
        } else {
            const hashedPassword = Buffer.from(values.password).toString('base64');
            setPhoneNumber(values.userName);
            if (sessionId && values.captcha) {
                signIn({
                    variables: {
                        phoneNumber: values.userName,
                        password: hashedPassword,
                        isMemberId: values.isMemberId === '1' ? true : false,
                        sessionId,
                        authCode: values.captcha
                    }
                });
            } else {
                setError('INVALID_CAPTCHA');
            }
            
        }
    };

    const onRedirectClick = () => {
        let url = process.env.REACT_APP_REDIRECT_SITE_URL;
        if (url) {
            window.location.assign(url);
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        setCaptchaError("");
    };
    const onSuccess = () => {
        setCaptchaError("");
        getCaptchaCode({
            variables: {
                userName: phoneNumber
            }
        });
    };
    const onFail = () => {
        setCaptchaError('INVALID_CAPTCHA');
    };
    const generateCaptcha = () => {
        const sessionId: any = cuid();
        setSessionId(sessionId);
        getCaptchaCode({
            variables: {
                userName: sessionId
            }
        });
    }
    return (
        <>
            <Form
                name="form"
                form={form}
                initialValues={{ isMemberId: '0' }}
                onFinish={onFinish}
            >
                {error && <Alert
                    className="auth-error"
                    message={maintenanceData && maintenanceData.isMaintenance ? error : <FormattedMessage id={error} values={{
                        0: primaPhone,
                        1: selectMemberId ? intl.formatMessage({ id: 'GENERAL_MEMBER_ID' }) : intl.formatMessage({ id: 'GENERAL_PHONE_NUMBER' }),
                        2: 5 - loginFailCounter
                    }} />}
                    type="error"
                    showIcon
                />}
                <Form.Item name="isMemberId">
                    <Radio.Group onChange={(e) => {
                        form.setFieldsValue({ userName: '' });
                        setSelectMemberId(!!+e.target.value);
                    }}>
                        <Radio value="0"><FormattedMessage id="GENERAL_PHONE_NUMBER" /></Radio>
                        <Radio value="1"><FormattedMessage id="GENERAL_MEMBER_ID" /></Radio>
                    </Radio.Group>
                </Form.Item>
                <div className='redirect-container' onClick={onRedirectClick}>
                    <div className='redirect-name'>
                        <FormattedMessage id='REDIRECT_SITE' />
                    </div>
                </div>
                {!selectMemberId && <Form.Item
                    name="userName"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                        { min: 8, message: intl.formatMessage({ id: 'LOGIN_USERNAME_VALIDATE' }) }]}>
                    <PhoneInput />
                </Form.Item>}
                {selectMemberId && <Form.Item
                    name="userName"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'LOGIN_MEMBERID_REQUIRED' }) },
                        { min: memberIdPrefix.length + 5, message: intl.formatMessage({ id: 'LOGIN_MEMBERID_VALIDATE' }) }]}>
                    <Input maxLength={10} placeholder={intl.formatMessage({ id: 'AUTH_REFERRAL_ID_SAMPLE' })} />
                </Form.Item>}
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'LOGIN_PASSWORD_REQUIRED' }) },
                        { min: 8, message: intl.formatMessage({ id: 'LOGIN_PASSWORD_REQUIRED' }) }]}>
                    <Input.Password maxLength={30} placeholder={intl.formatMessage({ id: 'GENERAL_PASSWORD' })} />
                </Form.Item>
                <Captcha value={captcha} refresh={generateCaptcha}/>
                <Form.Item className="auth-button">
                    <Button block type="primary" htmlType="submit" loading={loading}>
                        <FormattedMessage id="AUTH_BUTTON_LOGIN" />
                    </Button>
                </Form.Item>
            </Form>
            <Modal width={480}
                title={<FormattedMessage id="DRAG_TO_VERIFY" />}
                visible={isModalVisible}
                onCancel={handleCancel}
                destroyOnClose
                footer={null}>
                <div >
                    {captchaError && <Alert
                        className="auth-error"
                        message={maintenanceData && maintenanceData.isMaintenance ? captchaError : <FormattedMessage id={captchaError} />}
                        type="error"
                        showIcon
                    />}
                    <Vertify
                        width={320}
                        height={160}
                        onSuccess={() => onSuccess()}
                        onFail={() => onFail()}
                    // onRefresh={() => alert('refresh')}
                    />
                </div>
            </Modal>
        </>
    );
}
export default LoginPasswordForm;
