

export const maskLabelHelper = (value: string) => {
    if (value) {
        const trimVal = value.substr(value.length - 3);
        return `**********${trimVal}`;
    } else {
        return;
    }
}

export const maskValuesHelper = (value: string) => {
    if (value && value !== '0' && value !== '0.00') {
        let trimVal = value.substr(0, 1);
        let maskedVal = value.replace(new RegExp("[0-9]", "g"), "*");
        return trimVal.concat(maskedVal);
    } else {
        return 0;
    }
}

export const getStrMemberId = (memberId: any) => {
    let memberIdPrefix = process.env.REACT_APP_MEMBERID_PREFIX ? process.env.REACT_APP_MEMBERID_PREFIX : '';
    return memberIdPrefix + memberId.toString();
}

export const getEligibleRanks = (memberRanks: string, pageType: string) => {
    switch (pageType) {
        case 'CORPORATE_FUND':
        case 'HIERARCHY': {
            return (memberRanks === 'DIB' || memberRanks === 'PIB' || memberRanks === 'ADIB') ? true : false;
        }
        default:
            return false;
    }
}