import { Result } from 'antd';
// import { ReactComponent as Logo } from './assets/images/CN_logo.svg';
// import { ReactComponent as Logo } from './assets/images/celebration/logo.svg';
import { ReactComponent as Logo } from './assets/images/herzen/logo.svg';
import background from './assets/images/celebration/background.jpg';

const Maintenance = () => {
    return (
        <div className="maintenance-container" style={{background: `url(${background}) 50% center / cover`}}>
            <Result
                icon={<Logo className="default-logo" height="150" width="350" />}
                title={
                    <>
                        <div className="maintenance-text">
                            <p><b>SITE MAINTENANCE</b></p>
                            <span>In order to provide member with better browsing experience, Herzen user portal will be undergoing upgrading and maintenance work from 10.00am to 12.00pm. (GMT+8)</span>
                            <span>Please stay tuned and we appreciate for kind patience.</span>
                            <span>For further inquiry, kindly contact Herzen International Customer Service at +60 10 432 3643 for any further inquiries.</span>
                        </div>
                        <div className="maintenance-text">
                            <p><b>网站维护</b></p>
                            <span>为了让会员获得更好的浏览体验，网站将于早上10时至下午12时(GMT+8)进行系统升级维护。因此为您带来的任何不便，我们深表歉意。</span>
                            <span>欢迎您联系币码国际客服 ＋6010 432 3643了解更多详情。</span>
                            <span>感谢您的耐心等待！</span>
                        </div>
                    </>}
                />
        </div>
    );
}

export default Maintenance;