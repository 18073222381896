import { Form, Input } from "antd";
import { Rule } from "antd/lib/form";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "./CustomPasswordInput.less";

interface Props {
  name: string;
  length: number;
  label?: string;
  rules?: Rule[];
  limitWidth: boolean;
}

const PinCodeInput = ({ name, length, label, rules, limitWidth }: Props) => {
  const intl = useIntl();
  const [inputRules, setInputRules] = useState<Rule[]>([]);


  useEffect(() => {
    if (rules) setInputRules(rules);
    else {
      setInputRules([
        { required: true, message: intl.formatMessage({ id: "PIN_VALIDATE" }) },
        { pattern: new RegExp(/^\d+$/), message: intl.formatMessage({ id: 'INVALID_PIN' }) },
        { min: 6, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
      ]);
    } // eslint-disable-next-line
  }, [rules]);

  const handleNumberOnly = (e: any) => {
    var reg = new RegExp('^[0-9]$');
    if (!reg.test(e.key)) {
      e.preventDefault();
    }
  }

  return (<>
    <Form.Item
      className="custom-password-input"
      name={name}
      label={label ? label : ''}
      rules={inputRules}
    >
      <Input.Password
        className={limitWidth === true ? "input-pin" : ""}
        onKeyPress={handleNumberOnly}
        type="number"
        maxLength={length}
        autoComplete="off"
        placeholder={intl.formatMessage({ id: "PIN_PLACEHOLDER" })} />
    </Form.Item>
  </>);
}
export default PinCodeInput;