import "./App.less";
import { Layout } from "antd";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Login from "./pages/auth/Login";
import background from './assets/images/celebration/background.jpg';
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import { CLEAR_CURRENT_USER, SET_LOGIN_STATUS, useAuth } from "./hooks/auth/AuthContext";
import ForgotPassword from "./pages/auth/ForgotPassword";
import { clearAllTokens } from "./shared/helpers/set-token-cookies.helper";
import { LOGOUT, GET_THEME } from "./pages/auth/auth.gql";
import { useMutation, useQuery } from "@apollo/client";
const { Content } = Layout;

const AppAnonymous = () => {
    const history = useHistory();
    const location: any = useLocation();
    const { from } = location.state || { from: { pathname: '/' } };
    const { authState, authDispatch } = useAuth();
    const [backgroundImg, setBackgroundImg] = useState<any>({ background: `url(${background}) 50% center / cover` });
    const [logout, { data, error }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const { data: themeData } = useQuery(GET_THEME, { fetchPolicy: 'no-cache' });

    //Redirect to the incoming path after login
    useEffect(() => {
        console.log('location', location);
        console.log('from', from);
        if (location.pathname === '/auth/register' && location.search && authState.isLogin) {
            logout();
        } else {
            if (authState.isLogin) {
                history.replace(from.pathname);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.isLogin]);

    useEffect(() => {
        if ((data && data.logout) || error) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    useEffect(() => {
        if(themeData && themeData.theme)
            setBackgroundImg({ background: `url(${themeData.theme?.mainBgUrl ?? background}) 50% center / cover` });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeData]);
    
    return (
        <Layout className="login-container">
            <Content className="anonymous-container" style={{...backgroundImg}}>
                <div style={{ width: "100%" }}>
                    <Switch>
                        <Route path="/auth/forgot-password" component={ForgotPassword} />
                        {/* <Route path="/auth/register" component={Register} /> */}
                        <Route path="/auth" component={Login} />
                    </Switch>
                    <div className="footer-container font-caption">
                        <FormattedMessage id="COPY_RIGHT" values={{
                            year: new Date().getFullYear().toString()
                        }} />
                    </div>
                </div>
            </Content>
        </Layout>);
}

export default AppAnonymous;