
export const setDBLanguageToGet = (locale: string) => {
    switch (locale) {
        case 'en':
            return 'en_US';
        case 'zh-CN':
            return 'zh_CN';
        case 'zh-Hant':
            return 'zh_Hant';
        case 'th':
            return 'th';
        case 'ms':
            return 'ms';
        // case 'vn':
        //     return 'vn';
        default:
            return 'en';
    }
}
