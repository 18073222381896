import { ApolloError } from "@apollo/client";
import { Button, Checkbox, Form, Modal } from "antd";
import { Dispatch, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLayout } from "../../../../hooks/layout/LayoutContext";

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
    displayMsg: { title: string | undefined, submitBtnTitle: string | undefined, attachedText: string | undefined, id: string }
    canClose: boolean | undefined;
    tncDocument: any;
    insertAgreedTNC: { callFunction: any, callError: ApolloError | undefined }
}

const CorporateFundTNCDialog = ({ displayMsg, modalVisible, canClose = false, tncDocument, insertAgreedTNC }: Props) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const { layoutState } = useLayout();

    const handleOk = async () => {
        form.validateFields()
            .then(async (values) => {
                await insertAgreedTNC.callFunction({
                    variables: {
                        tncId: displayMsg.id,
                        language: layoutState.locale
                    }
                });
                modalVisible.setVisible(false);
                form.resetFields();
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const handleCancel = async () => {
        modalVisible.setVisible(false);
    };

    return (<>
        <Modal
            title={displayMsg.title}
            closable={canClose}
            visible={modalVisible.visible}
            destroyOnClose={true}
            onOk={handleOk}
            footer={[<Button key="submit"
                type="primary"
                onClick={handleOk}>{displayMsg.submitBtnTitle}</Button>]}
            onCancel={handleCancel}>
            {
                displayMsg.attachedText &&
                <div className="tnc-frame"
                    dangerouslySetInnerHTML={{
                        __html: displayMsg.attachedText!
                    }}>
                </div>
            }
            <Form form={form} className='tnc-checkbox'>
                <Form.Item name="corporateFundTNC" valuePropName="checked" rules={[
                    {
                        required: true, transform: value => (value || undefined),
                        type: 'boolean', message: intl.formatMessage({ id: 'AUTH_TNC_ENFORCE' })
                    }
                ]}>
                    <Checkbox><FormattedMessage id={`CORPORATE_FUND_TNC_DOC`} /></Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    </>)
}
export default CorporateFundTNCDialog;