import { gql } from "@apollo/client";

export const GET_USER_PROFILE = gql`
query UserProfile {
        userProfile {
            id,
            name,
            userName,
            photoUrl,
            memberId,
            strMemberId,
            countryId,
            phone,
            phoneVerified,
            email,
            emailVerified,
            gender,
            kycApproved,
            pinStatus,
            loginPasswordStatus,
            bankAccountStatus,
            kycStatus,
            isActive,
            wallets,
            pointWallets,
            requiredPasswordChange,
            mt4Wallet,
            unreadNotification,
            address1,
            address2,
            city,
            state,
            postcode,
            verifiedForTransaction,
            hasSponsor,
            userRank,
            dialCode,
            dob,
            preferredEmailLanguage,
            pointWallets,
            pointAccepted,
            mainAccountId,
            mainAccountMemberId
        }
}`;


export const CREATE_PIN = gql`
mutation UserPinCreate($pinCode:String!){ 
	userPinCreate(pinCode:$pinCode){ 
		status, id 
	} 
}
`;

export const AFFIRM_PIN = gql`
mutation UserPinAffirm($pinCode:String!){ 
	userPinAffirm(pinCode:$pinCode){ 
		status, id 
	} 
}
`;
