import React, { Suspense, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.less';
import AppAnonymous from './AppAnonymous';
import AppProtected from './AppProtected';
import { CLEAR_CURRENT_USER, SET_LOGIN_STATUS, useAuth } from './hooks/auth/AuthContext';
import { IntlProvider } from 'react-intl';
import { useLayout } from './hooks/layout/LayoutContext';
import NoMatch from './NoMatch';
import jwtDecode from 'jwt-decode';
import Maintenance from './Maintenance';
import { useMutation } from '@apollo/client';
import { VERIFY_SESSION } from './pages/auth/auth.gql';

//Import i18n files
import messages_en from './assets/i18n/en.json';
import message_zh_CN from './assets/i18n/zh_CN.json';
import message_zh_Hant from './assets/i18n/zh_Hant.json';
import message_th from './assets/i18n/th.json';
import message_ms from './assets/i18n/ms.json';
import { clearAllTokens } from './shared/helpers/set-token-cookies.helper';
import { client } from './apollo-clients';

const App = () => {
  const { authState, authDispatch } = useAuth();
  const [cookies] = useCookies();
  const { layoutState } = useLayout();
  const [verifySession, { data, error }] = useMutation(VERIFY_SESSION, {
    errorPolicy: "all"
  });
  const maintenanceMode = 0;

  const messages: any = {
    'en': messages_en,
    'zh-CN': message_zh_CN,
    'zh-Hant': message_zh_Hant,
    'th': message_th,
    'ms': message_ms,
    //'vn': message_vn
  };

  //First load
  useEffect(() => {
    const token = cookies[process.env.REACT_APP_REFRESH_TOKEN || 'scf-rt'];
    if (token) {
      const { isActive, sid }: any = jwtDecode(token);
      if (isActive) {
        verifySession({ variables: { sessionId: sid }});
        authDispatch({ type: SET_LOGIN_STATUS, payload: token ? true : false });
      }
    }

    const webVersion: string = localStorage.getItem('wv') || 'no-version';
    console.log('@current: ', webVersion);
    checkWebVersion(webVersion);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkWebVersion = async (currentVersion: string) => {
    const url = process.env.REACT_APP_WEBVERSION ?? '';
    const res = await fetch(url, {
      method: 'GET'
    });
    const result = await res.json();
    if (result) {
      if (currentVersion !== result.version) {
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then((names) => {
            for (const name of names) {
              caches.delete(name);
            }
          });
        }
        // delete browser cache and hard reload
        window.location.reload();
        localStorage.setItem('wv', result.version);
        console.log('@reload:', result.version);
      }
    }
  }

  useEffect(() => {
    if (error || (data && data.verifySession &&
      data.verifySession.status && data.verifySession.status === 'FAIL')) {
      clearAllTokens();
      authDispatch({ type: CLEAR_CURRENT_USER });
      authDispatch({ type: SET_LOGIN_STATUS, payload: false });
      if (client) {
				client.cache.reset();
			}
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return (
    <>
      <IntlProvider locale={layoutState.locale} messages={messages[layoutState.locale]}>
        <Router>
          <Switch>
            <Route exact path="/" render={({ location }) => {
              return maintenanceMode ? <Redirect to={{ pathname: '/maintenance' }} /> :
                (authState.isLogin ? <Redirect to={{ pathname: '/member' }} /> :
                  <Redirect to={{ pathname: '/auth', state: { from: location } }} />)
            }} />
            {/* <Route path="/herzenref" render={({ location }) => {
              return maintenanceMode ? <Redirect to={{ pathname: '/maintenance' }} /> :
                (authState.isLogin ? <Redirect to={{ pathname: '/member' }} /> :
                  <Redirect to={{ pathname: '/auth/register', search: location.search }} />)
            }} /> */}
            <Route path="/member" render={({ location }) => {
              if (!authState.isLogin) {
                return (<Redirect to={{ pathname: '/auth', state: { from: location } }} push />);
              } else {
                return (
                  <Suspense fallback={<AppAnonymous />}>
                    <AppProtected />
                  </Suspense>);
              }
            }} />
            <Route path="/auth" render={({ location }) => {
              if (maintenanceMode) {
                return (<Redirect to={{ pathname: '/maintenance' }} />);
              } else {
                return (<AppAnonymous />);
              }
            }} />
            <Route path="/maintenance" component={Maintenance} />
            <Route path="*" component={NoMatch} />
          </Switch>
        </Router>
      </IntlProvider>
    </>
  );
}

export default App;
