import { Button, Form, Input } from "antd";
import { useEffect } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

interface Props {
    value: string,
    refresh: () => void
}

const Captcha = ({ value, refresh }: Props) => {
    const intl = useIntl();
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            refresh();
        }, 300000);

        return () => {
            clearInterval(intervalId) 
        }; // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let showNum = [];
        let canvasWidth = 100;
        let canvasHeight = 35;
        let canvas: any = document.getElementById('captcha');
        let context = canvas.getContext('2d');
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        if (value) {
            const saCode = value.split('');
            for (let i = 0; i <= 3; i++) {
                let sDeg = (Math.random()*30*Math.PI) / 180;
                let cTxt = saCode[i];
                showNum[i] = cTxt ? cTxt.toLowerCase() : '';
                let x = 10 + i*20;
                let y = 20 + Math.random()*8;
                context.font = 'bold 23px Comic Sans MS';
                context.translate(x, y);
                context.rotate(sDeg);
            
                context.fillStyle = randomColor();
                context.fillText(cTxt, 0, 0);
            
                context.rotate(-sDeg);
                context.translate(-x, -y);
            }
            // for (let i = 0; i <= 5; i++) {
            //     context.strokeStyle = randomColor();
            //     context.beginPath();
            //     context.moveTo(
            //     Math.random() * canvasWidth,
            //     Math.random() * canvasHeight
            //     );
            //     context.lineTo(
            //     Math.random() * canvasWidth,
            //     Math.random() * canvasHeight
            //     );
            //     context.stroke();
            // }
            for (let i = 0; i < 30; i++) {
                context.strokeStyle = randomColor();
                context.beginPath();
                let x = Math.random() * canvasWidth;
                let y = Math.random() * canvasHeight;
                context.moveTo(x,y);
                context.lineTo(x+1, y+1);
                context.stroke();
            }
            canvas.fillStyle = 'blue';
        } 
        
    }, [value]);

    const randomColor = () => {
        const colors= ['aqua', 'black', 'blue', 'fuchsia', 'gray', 'green', 
            'lime', 'maroon', 'navy', 'olive', 'orange', 'purple', 'red', 
            'silver', 'teal'];
        let randomValue: any = colors[Math.floor(Math.random() * colors.length)];
        return randomValue;
    }

    return (
        <div className="captcha-panel">
            <Form.Item name="captcha" className="captcha-input">
                <Input maxLength={4} placeholder={intl.formatMessage({ id: "VERIFICATION_INPUT_PLACEHOLDER" })} />
            </Form.Item> 
            <canvas id="captcha" width={0} height={0} style={{ background: 'white', cursor: 'pointer'}} onClick={refresh}/>
            <Button type="primary" icon={<ReloadOutlined />} onClick={refresh} className="captcha-refresh-btn" />
        </div>
    );
}

export default Captcha;