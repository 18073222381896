import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

const app = initializeApp({
    apiKey: "AIzaSyBofn7l8m1znhy6yRUzOJ4R4hsuZ6wKDG4",
    authDomain: "prima-international.firebaseapp.com",
    databaseURL: "https://prima-international-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "prima-international",
    storageBucket: "prima-international.appspot.com",
    messagingSenderId: "781979504979",
    appId: "1:781979504979:web:8bceaf0fa59078508111a6",
    measurementId: "G-8J2YBJ9RT2"
  });

  export const db = getDatabase(app);
  