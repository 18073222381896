

import { Modal } from "antd";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useIntl } from "react-intl";

interface Props {
    modalVisible: { visible: boolean; setVisible: Dispatch<SetStateAction<boolean>> };
    videoNames: string[];
    videoTitle: string;
    videoBlobContainer: string;
}

const VideoViewer = ({ modalVisible, videoNames, videoTitle, videoBlobContainer }: Props) => {
    const intl = useIntl();
    const urlAccName = process.env.REACT_APP_STORAGE_ACCOUNTNAME ? process.env.REACT_APP_STORAGE_ACCOUNTNAME : '';

    const handleCancel = useCallback(() => {
        modalVisible.setVisible(false);
    }, [modalVisible]);

    const getModalWidth = () => window.innerWidth < 576 ? window.innerWidth: (window.innerWidth / 2) + 44; 
    const getWidth = () => window.innerWidth < 576 ? window.innerWidth - 64: window.innerWidth / 2; 
    const getHeight = () => window.innerWidth < 576 ? (window.innerWidth - 64) / 560 * 315: (window.innerWidth / 2) / 560 * 315; 

    return (
        <>
            <Modal width={getModalWidth()} title={intl.formatMessage({ id: videoTitle })}
                visible={modalVisible.visible}
                onCancel={handleCancel}
                destroyOnClose
                footer={null}>
                <div className="video-container">
                    {videoNames && videoNames.length > 0 &&
                        videoNames.map((val: any) => {
                            return (
                                <video key={videoTitle} controls width={getWidth()} height={getHeight()}>
                                    <source 
                                        src={`https://${urlAccName}/${videoBlobContainer}/${intl.formatMessage({ id: val })}.mp4`}
                                        type="video/mp4" />
                                </video>
                            );
                        })
                    }
                </div>
            </Modal>
        </>
    );
};
export default VideoViewer;