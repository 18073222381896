import { Select } from "antd";
import { SET_LANGUAGE, useLayout } from "../../../hooks/layout/LayoutContext";
import './LanguageSwitcher.less';

const LanguageSwitcher = () => {
    const { layoutDispatch } = useLayout();

    const setLocale = (selectedValue: string, option: any) => {
        localStorage.setItem('pfxl', option.value);
        layoutDispatch({ type: SET_LANGUAGE, payload: option.value })
    }

    return (
        <div className="language-switcher-container">
            <Select
                className="language-dropdown"
                dropdownClassName="language-select-dropdown"
                defaultValue={localStorage.getItem('pfxl') ? localStorage.getItem('pfxl')?.toString() : "en"}
                onChange={setLocale}>
                <Select.Option value="en">
                    English
                </Select.Option>
                <Select.Option value="zh-CN">
                    简体中文
                </Select.Option>
                <Select.Option value="zh-Hant">
                    繁體中文
                </Select.Option>
                <Select.Option value="th">
                    ไทย
                </Select.Option>
                <Select.Option value="ms">
                    Bahasa Melayu
                </Select.Option>
                {/* <Select.Option value="vn">
                    Tiếng Việt
                </Select.Option> */}
            </Select>
        </div>
    )
}

export default LanguageSwitcher;