import { Button, Modal } from "antd";
import { Dispatch, SetStateAction } from "react";
import { REDIRECT_TO_APP, useLayout } from "../../../../hooks/layout/LayoutContext";
import { useAuth } from "../../../../hooks/auth/AuthContext";

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
    canClose: boolean | undefined;
}

const RedirectAppPopUpDialog = ({ modalVisible, canClose = true }: Props) => {
    const { layoutState, layoutDispatch } = useLayout();
    const { authState } = useAuth();
    const handleOk = async () => {
        var url = process.env.REACT_APP_REDIRECT_QR_PAYMENT_URL ? process.env.REACT_APP_REDIRECT_QR_PAYMENT_URL : "";
        let memberId = authState.userProfile?.memberId ? authState.userProfile?.memberId : "";
        let memberPrefix = process.env.REACT_APP_MEMBERID_PREFIX ? process.env.REACT_APP_MEMBERID_PREFIX : "";

        if (layoutState.redirectAppDialog.type === "register") {
            url = url + "/auth/register?refId=" + memberPrefix + memberId
        }
        else {
            url = url + "/auth/login?refId=" + memberPrefix + memberId
        }
        window.open(url, "_blank");
        
        layoutDispatch({ type: REDIRECT_TO_APP, payload: { ...layoutState.redirectAppDialog, isRedirect: false, type: '' } });
        modalVisible.setVisible(false);
    };

    const handleCancel = async () => {
        layoutDispatch({ type: REDIRECT_TO_APP, payload: { ...layoutState.redirectAppDialog, isRedirect: false, type: '' } });
        modalVisible.setVisible(false);
    };

    const displayMsg = {
        title: "Redirect to X.com for " + layoutState.redirectAppDialog.type, 
        attachedText: "Please click on confirm to navigate to x.com for further actions. ",
        submitBtnTitle: 'Confirm'
    }

    return (<>
        <Modal title={displayMsg.title} closable={canClose} visible={modalVisible.visible} onOk={handleOk}
            onCancel={handleCancel}
            cancelText={"Cancel"}
            footer={[<Button key="submit" type="primary" onClick={handleOk}>{displayMsg.submitBtnTitle}</Button>]}>
            <p style={{whiteSpace: "pre-line"}}> {displayMsg.attachedText}</p>
        </Modal>
    </>)
}
export default RedirectAppPopUpDialog;