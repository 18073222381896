import { useMutation } from "@apollo/client";
import { Alert, Button, Modal } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UPDATE_POINTAGREEMENT } from "../../Home.gql";
import useNotification from "../../../../hooks/layout/useNotification";
import { LOGOUT } from "../../../auth/auth.gql";
import { clearAllTokens } from "../../../../shared/helpers/set-token-cookies.helper";
import { CLEAR_CURRENT_USER, SET_LOGIN_STATUS, useAuth } from "../../../../hooks/auth/AuthContext";

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
}

const PointAgreeDialog = ({ modalVisible }: Props) => {
    const intl = useIntl();
    const [error, setError] = useState<any>();
    // const [consentSelection, setConsentSelection] = useState<any>();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [updatePamm, { data: updatePointData, error: updatePointError }] = useMutation(UPDATE_POINTAGREEMENT, { fetchPolicy: "no-cache", errorPolicy: "all" });
    // const dummyText = "<p>STRICTLY PRIVATE AND CONFIDENTIAL</p\n\n <p>RE:LETTER OF CONSENT TO SELECT THE NEW PERCENTAGE ALLOCATION MANAGEMENT MODULE(NEW PAMM)(CONSENT LETTER)</p>\n\n<p>1. Herzen International Limited(Registration No:233326)</p>";
    const [logout, { data: logoutData }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const { authDispatch } = useAuth();

    const confirmationPAMM = (selection: any) => {
        // setConsentSelection(selection);
        updatePamm();
    };

    useEffect(() => {
        if (logoutData?.logout) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutData]);

    useEffect(() => {
        if (updatePointError) {
            setErrorNotification(intl.formatMessage({ id: "PAMM_FAILED" }));
        } else if (updatePointData) {

            if (updatePointData.updatePointAgreement && updatePointData.updatePointAgreement.status === 'SUCCESS') {
                setSuccessNotification(intl.formatMessage({ id: 'POINT_SUCCESSFUL_AGREE' }));
                modalVisible.setVisible(false);
            } else if (updatePointData.updatePointAgreement && updatePointData.updatePointAgreement.isSuccess === false) {
                setError(updatePointData.updatePointAgreement.message);
                logout();
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePointData, updatePointError]);

    // displayMsg={{
    //     title: thirdPartyTNCData && thirdPartyTNCData?.tncByType?.tnc?.title[setDBLanguageToGet(layoutState.locale)],
    //     submitBtnTitle: intl.formatMessage({ id: "SUBMIT" }),
    //     attachedText: thirdPartyTNCData && thirdPartyTNCData?.tncByType?.tnc?.content[setDBLanguageToGet(layoutState.locale)],
    //     id: thirdPartyTNCData && thirdPartyTNCData?.tncByType?.tnc?.id,
    // }}

    return (<>
        <Modal title={intl.formatMessage({ id: 'POINT_AGREEMENT' })} closable={false} visible={modalVisible.visible}
            footer={[<Button key="agree" type="primary" onClick={() => { confirmationPAMM('AGREE') }}>{intl.formatMessage({ id: 'POINT_AGREE_BTN' })}</Button>,
                // <Button key="disagree" type="primary" danger onClick={() => { confirmationPAMM('DISAGREE') }}>{intl.formatMessage({ id: 'DISAGREE' })}</Button>,
                // <Button key="skip" type="default" onClick={() => { confirmationPAMM('DEFAULT') }}>{intl.formatMessage({ id: 'SKIP' })}</Button>
            ]}>
            <div className="tnc-frame">
                <p><FormattedMessage id='POINT_AGREEMENT_1'/></p>
                <p><FormattedMessage id='POINT_AGREEMENT_2'/></p>
                <p><FormattedMessage id='POINT_AGREEMENT_3'/></p>
                <p><FormattedMessage id='POINT_AGREEMENT_4'/></p>
            </div>
            {error && (
                <Alert message={<FormattedMessage id={error} />} type="error" showIcon />
            )}
            {/* <Form name="pamm" form={form}>
                <Row>
                    <Col flex="1">
                        <FormattedMessage id="ENTER_CODE" /><span>:</span>
                    </Col>
                    <Col flex="2">
                        <PinCodeInput name="pinCode" length={6} limitWidth={false} />
                    </Col>
                </Row>
            </Form> */}
        </Modal>
    </>)
}
export default PointAgreeDialog;