import { gql } from "@apollo/client";

export const UPDATE_PROFILE = gql`
    mutation UpdateCustomerProfile($memberInput:MemberInput!){ 
        updateCustomerProfile(memberInput:$memberInput){ 
            status, id 
        } 
    }
`;

export const GET_CODE = gql`
    mutation UserVerification($verificationType:String!, $email: String, $language: String){ 
        userVerification(verificationType:$verificationType, email:$email, language: $language){ 
            status, id 
        } 
    }
`;

export const VERIFY_SUBMIT_WITH_CODE = gql`
    mutation UserVerificationWithCode($verificationType:String!, $verificationCode: String!, $email: String, $pinCode:String!){ 
        userVerificationWithCode(verificationType:$verificationType,verificationCode:$verificationCode, email:$email, pinCode:$pinCode){ 
            status, id 
        } 
    }
`;

export const UPDATE_PIN = gql`
    mutation UserUpdatePin($pinCode:String!, $verificationCode: String!){ 
        userUpdatePin(pinCode:$pinCode,verificationCode:$verificationCode){ 
            status, id 
        } 
    }
`;