import { Layout } from 'antd';
import './GeneralFooter.less';
const { Footer } = Layout;

const GeneralFooter = () => {
    return (
        <Footer className="site-layout-footer">©{new Date().getFullYear().toString()} Herzen International. All rights Reserved.</Footer>
    )
}

export default GeneralFooter;