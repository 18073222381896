import { Button } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FormattedMessage } from "react-intl";
import { setGeneralCookie } from "../../shared/helpers/set-general-cookie.helper";
interface Props {
    isSignUp?: boolean;
    phoneNumber: string | undefined;
    hasError: boolean;
    requestStatus: () => void;
    startCountdown: boolean;
    isRegister?: boolean;
    email?: string;
    setError?: any;
}
const CodeRequestButton = ({ requestStatus, isSignUp = false,
    phoneNumber, hasError, startCountdown, isRegister = false, email = '', setError }: Props) => {
    // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies();
    const [cooldown, setCooldown] = useState<number>(0);
    const [cooldownTimer, setCooldownTimer] = useState<number>();
    // const [initiateCountdown, setInitiateCountdown] = useState<boolean>(false);

    useEffect(() => {
        const cooldownFromCookie = cookies[`c-cooldown-${isSignUp}`];
        const cookiesCountdownTimer = cookies[`c-cooldown-start-${isSignUp}`];
        let countdownTImer = 179;

        if (cookiesCountdownTimer) {
            countdownTImer = +cookiesCountdownTimer;
        }

        if (cooldownFromCookie) {
            const timePassed = moment().unix() - (+cooldownFromCookie);
            //due to latency, reduce 1 sec
            const remainingSeconds = timePassed > countdownTImer ? 0 : (countdownTImer - timePassed);
            setCooldown(remainingSeconds);
            initiateTimer(remainingSeconds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const clearTimer = () => {
            removeCookie(`c-cooldown-${isSignUp}`);
            removeCookie(`c-cooldown-start-${isSignUp}`);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
        }
        return () => clearTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cooldownTimer])

    useEffect(() => {
        // setInitiateCountdown(startCountdown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startCountdown])

    useEffect(() => {
        if (hasError) {
            removeCookie(`c-cooldown-${isSignUp}`);
            removeCookie(`c-cooldown-start-${isSignUp}`);
            setCooldown(0);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasError]);

    const initiateTimer = (amount: number) => {
        const cooldownInterval: number = window.setInterval(() => {
            if (amount > 0) {
                setCooldown(amount--);
            } else {
                setCooldown(0);
                clearInterval(cooldownInterval);
            }
        }, 1000);
        setCooldownTimer(cooldownInterval);
    }

    const requestCode = () => {
        if (isRegister) {
            if (phoneNumber && email && cooldown === 0) {
                //1 minute cooldown
                //if (initiateCountdown === true) {
                setGeneralCookie(`c-cooldown-${isSignUp}`, moment().unix().toString(), 179);
                setCooldown(179);
                if (cooldownTimer) {
                    clearInterval(cooldownTimer);
                }
                initiateTimer(179);
                //}
                requestStatus();
            } else if(!email) {
                setError('LOGIN_EMAIL_REQUIRED');
            } else if(!phoneNumber) {
                setError('LOGIN_USERNAME_REQUIRED');
            }
        } else {
            if (phoneNumber && cooldown === 0) {
                //1 minute cooldown
                //if (initiateCountdown === true) {
                setGeneralCookie(`c-cooldown-${isSignUp}`, moment().unix().toString(), 179);
                setCooldown(179);
                if (cooldownTimer) {
                    clearInterval(cooldownTimer);
                }
                initiateTimer(179);
                //}
                requestStatus();
            }
        }
    }


    return (
        <Button disabled={cooldown > 0} onClick={requestCode}>
            {(!cooldown || cooldown === 0) ? <FormattedMessage id="AUTH_REQUEST_CODE" />
                : (cooldown > 1 ? <FormattedMessage id="AUTH_COOL_DOWNS" values={{ cooldown }} />
                    : <FormattedMessage id="AUTH_COOL_DOWN" values={{ cooldown }} />)}
        </Button>)

}

export default CodeRequestButton;