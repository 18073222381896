import { Button, Divider, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { InfoCircleOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import "./Profile.less";
import { useAuth } from "../../../hooks/auth/AuthContext";
import NumberFormat from "react-number-format";
//TODO: 
// 1. Add in exact Link path

const Profile = ({ profileData }: any) => {
    const { authState } = useAuth();
    const [profile, setProfile] = useState<any>();
    const [maskData, setMaskData] = useState(true);
    const mask = '*****.**';

    useEffect(() => {
        if (profileData && profileData.profile) {
            const _profile = {
                MT4Amount: +profileData.profile.MT4Amount,
                MT4AllTradeBalance: +profileData.profile.MT4AllTradeBalance,
                MT4LatestTransfer: +profileData.profile.MT4LatestTransfer,
                MT4LatestWithdrawal: +profileData.profile.MT4LatestWithdrawal,
                WalletLatestCommission: +profileData.profile.WalletLatestComission,
                WalletLatestWithdrawal: +profileData.profile.WalletLatestWithdrawal,
                WPointLastAllocation: +profileData.profile.WPointLastAllocation,
                RPointLastWithdrawal: +profileData.profile.RPointLastWithdrawal,
                TeamMembers: +profileData?.profile?.TeamMembers,
                hasSelfTradeAccount: profileData.profile.hasSelfTradeAccount
            }
            setProfile(_profile);
        }
    }, [profileData]);

    const getAvailableAmount = () => {
        if (authState?.userProfile?.wallets?.WALLET) {
            const availableBalance = authState?.userProfile?.wallets?.WALLET?.availableBalance - authState?.userProfile?.wallets?.WALLET?.lockBalance;
            return availableBalance < 0 ? 0 : availableBalance;
        }
        else {
            return 0
        }
    }

    return (
        <div className="profile-container">
            <div>
                <div>
                    <div>{<FormattedMessage id="WALLET" />} ({<FormattedMessage id="USD" />})</div>
                    <div>
                        <Tooltip placement="top" title={<FormattedMessage id="MT5_WALLET_INFO" />} >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </div>
                </div>
                <div className="profile-details">
                    <div>
                        <FormattedMessage id="DOLLAR_SYMBOL" /> {maskData ? mask : <NumberFormat value={profile?.MT4LatestTransfer.toFixed(2) || '0.00'} displayType={'text'} thousandSeparator={true} />}
                    </div>
                    <div className="icon" onClick={() => setMaskData(!maskData)}>{maskData ? <EyeInvisibleOutlined /> : <EyeOutlined />}</div>
                </div>
                <div className="profile-details-latest-action">
                    <div>
                        <FormattedMessage id="AVAILABLE_FUND" />
                        <Tooltip placement="top" title={<FormattedMessage id="AVAILABLE_FUND_INFO" />} >
                            <InfoCircleOutlined style={{ marginLeft: '4px' }} />
                        </Tooltip>
                    </div>
                    <div>
                        <FormattedMessage id="DOLLAR_SYMBOL" /> <NumberFormat value={getAvailableAmount().toFixed(2) || '0.00'} displayType={'text'} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} />
                    </div>
                </div>
                <div className="profile-details-latest-action">
                    <div>
                        <FormattedMessage id="UNAVAILABLE_FUND" />
                        <Tooltip placement="top" title={<FormattedMessage id="UNAVAILABLE_FUND_INFO" />} >
                            <InfoCircleOutlined style={{ marginLeft: '4px' }} />
                        </Tooltip>
                    </div>
                    <div>
                        <FormattedMessage id="DOLLAR_SYMBOL" /> <NumberFormat value={authState?.userProfile?.wallets?.WALLET ? (+authState?.userProfile?.wallets?.WALLET?.lockBalance).toFixed(2) : '0.00'} displayType={'text'} thousandSeparator={true} />
                    </div>
                </div>
                <div className="profile-buttons">
                    <Button type="link" block>
                        <Link to="/member/mt5-account">
                            <FormattedMessage id="VIEW_MY_MT5" />
                        </Link>
                    </Button>
                    {/* <Divider orientation="center" className="center-divider" type="vertical" />
                    {profile && profile.hasSelfTradeAccount ?
                        <Button disabled={true} type="link" className="disabled-buttons" block>
                            <FormattedMessage id="APPLY_MT5" />
                        </Button> :
                        <Button type="link" block>
                            <Link to="/member/mt5-account/create">
                                <FormattedMessage id="APPLY_MT5" />
                            </Link>
                        </Button>
                    } */}
                </div>
            </div>
            <div>
                <div>
                    <div>{<FormattedMessage id="RPOINT" />}</div>
                    <div style={{ height: '22px' }}>
                        <Button type="primary">
                            <Link to={`/member/r-point-detail`}>
                                <FormattedMessage id="VIEW_DETAILS" />
                            </Link>
                        </Button>
                    </div>
                </div>
                <div className="profile-details">
                    <div>
                        {maskData ? mask : <NumberFormat value={authState?.userProfile?.pointWallets?.RPOINT?.availableBalance.toFixed(2) || '0.00'} displayType={'text'} thousandSeparator={true} />}
                    </div>
                    <div className="icon" onClick={() => setMaskData(!maskData)}>{maskData ? <EyeInvisibleOutlined /> : <EyeOutlined />}</div>
                </div>
                <div className="profile-details-latest-action">
                    <FormattedMessage id="LATEST_WITHDRAWAL" />
                    <div>
                        <NumberFormat value={profile?.RPointLastWithdrawal?.toFixed(2) || '0.00'} displayType={'text'} thousandSeparator={true} />
                    </div>
                </div>
                <div className="profile-details-latest-action" style={{ height: "34px" }}></div>
                <div className="profile-buttons">
                    <Button type="link" block>
                        <Link to={`/member/point-wallet-statement/${authState?.userProfile?.pointWallets?.RPOINT?.id}`}>
                            <FormattedMessage id="STATEMENT" />
                        </Link>
                    </Button>
                </div>
            </div>
            <div>
                <div>
                    <div>{<FormattedMessage id="WPOINT" />}</div>
                    <div style={{ height: '22px' }}>
                        <Button type="primary">
                            <Link to={`/member/w-point-detail`}>
                                <FormattedMessage id="VIEW_DETAILS" />
                            </Link>
                        </Button>
                    </div>
                </div>
                <div className="profile-details">
                    <div>
                        {maskData ? mask : <NumberFormat value={authState?.userProfile?.pointWallets?.WPOINT?.availableBalance.toFixed(2) || '0.00'} displayType={'text'} thousandSeparator={true} />}
                    </div>
                    <div className="icon" onClick={() => setMaskData(!maskData)}>{maskData ? <EyeInvisibleOutlined /> : <EyeOutlined />}</div>
                </div>
                <div className="profile-details-latest-action">
                    <FormattedMessage id="LATEST_ALLOCATION" />
                    <div>
                        <NumberFormat value={profile?.WPointLastAllocation?.toFixed(2) || '0.00'} displayType={'text'} thousandSeparator={true} />
                    </div>
                </div>
                <div className="profile-details-latest-action" style={{ height: "34px" }}></div>
                <div className="profile-buttons">
                    <Button type="link" block>
                        <Link to={`/member/point-wallet-statement/${authState?.userProfile?.pointWallets?.WPOINT?.id}`}>
                            <FormattedMessage id="STATEMENT" />
                        </Link>
                    </Button>
                    {/* <Divider orientation="center" className="center-divider" type="vertical" /> */}
                    {/* <Button type="link" block>
                        <Link to={`/member/allocate/wpoint`}>
                            <FormattedMessage id="ALLOCATE" />
                        </Link>
                    </Button> */}
                </div>
            </div>
            {/* <div>
                <div>
                    <div>{<FormattedMessage id="MY_TEAM" />}</div>
                </div>
                <div className="profile-details">
                    <NumberFormat value={profile?.TeamMembers || '0'} displayType={'text'} thousandSeparator={true} />
                </div>
                <div className="profile-details-latest-action">
                    <FormattedMessage id="TEAM_MEMBERS" />
                </div>
                <div className="profile-details-latest-action" style={{ height: "34px" }}></div>
                <div className="profile-buttons">
                    <Button type="link" block>
                        <Link to="/member/my-team">
                            <FormattedMessage id="VIEW_MY_TEAM" />
                        </Link>
                    </Button>
                </div>
            </div> */}
        </div>
    )
}

export default Profile;