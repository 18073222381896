import { gql } from "@apollo/client";

export const REGISTER = gql`
query SignUpCountries{
    signUpCountries{
        id,
        dialCode
    }
}
`
export const PHONE_SIGNUP = gql`
mutation PhoneSignUp($phoneNumber: String!, $countryId: String!, $referralMemberID: String!, $email: String!, $language: String!) 
{
    phoneSignUp(phoneNumber: $phoneNumber, countryId: $countryId, referralMemberID: $referralMemberID, email: $email, language: $language) 
    {
        status
        id,
    }
}
`

export const PHONE_SIGNUP_VERIFY_CODE = gql`
mutation PhoneSignUpVerifyCode($phoneNumber: String!, $code: String!, $name:String!, $referralMemberID: String!, $countryId: String!, $email: String!) 
{
    phoneSignUpVerifyCode(phoneNumber: $phoneNumber, code: $code, name: $name, referralMemberID: $referralMemberID, countryId: $countryId, email:$email) 
    {
        status,
    }
}
`

export const SET_PASSWORD = gql`
mutation RegisterSetPassword($phoneNumber: String!, $password:String!){
    registerSetPassword(phoneNumber: $phoneNumber, password:$password){
        a,
        r,
        sid
    }
}
`

export const SET_UPLINE = gql`
mutation SetUpline($uplineMemberId:String){
    setUpline(uplineMemberId:$uplineMemberId){
        status
    }
}
`

export const VALIDATE_UPLINE = gql`
mutation ValidateUpline($uplineMemberId:String){
    validateUpline(uplineMemberId:$uplineMemberId){
        status
    }
}
`

export const PHONE_SIGNIN = gql`
mutation PhoneSignIn($phoneNumber: String!) 
{
    phoneSignIn(phoneNumber: $phoneNumber) 
    {
        status,
        id
    }
}
`

export const PHONE_SIGNIN_VERIFY_CODE = gql`
mutation PhoneSignInVerifyCode($phoneNumber: String!, $code: String!) 
{
    phoneSignInVerifyCode(phoneNumber: $phoneNumber, code: $code) 
    {
        a,
        r,
        sid
    }
}
`

export const SIGN_IN = gql`
mutation SignIn($phoneNumber:String!, $password:String!, $authCode:String, $sessionId: String, $isMemberId: Boolean){
    signIn(phoneNumber:$phoneNumber, password:$password, authCode:$authCode, sessionId: $sessionId, isMemberId: $isMemberId){
        a,
        r,
        sid,
        loginFailCounter
    }
}
`;

export const LOGOUT = gql`
mutation Logout{
    logout{
        status
    }
}
`;

export const FORGOT_PASSWORD = gql`
mutation ForgotPassword($email:String! $strMemberId:String!){
    forgotPassword(email:$email, strMemberId:$strMemberId){
        status,
    }
}
`;

export const GET_REFERRAL_INFO = gql`
query GetReferralInfo($referralID:String!){
    getReferralInfo(referralID:$referralID){
        name
    }
}
`;

export const GET_THEME = gql`
query Theme {
    theme {
        mainBgUrl,
        loginBoxUrl,
        logoUrl
    }
}
`;

export const CAPTCHA_CODE = gql`
mutation CaptchaCode($userName:String!){
    captchaCode(userName:$userName){
        a,
        status
    }
}
`;

export const VERIFY_SESSION = gql`
mutation VerifySession($sessionId:String!){
verifySession(sessionId:$sessionId){
        status
    }
}
`;

export const GET_CONFIGURATIONS_LEGAL_DOC = gql`
query ConfigurationsLegalDoc{
    configurationsLegalDoc{
        id,
        value
    }
}
`;