import { Link, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { HomeOutlined, SolutionOutlined, UserOutlined, FileTextOutlined, DownloadOutlined, MailOutlined, PlaySquareOutlined, FileOutlined } from "@ant-design/icons";
import { Layout, Menu, Divider, Button } from "antd";
import SmallIcon from '../../../assets/images/celebration/logo.svg';
import phoneIcon from "../../../assets/images/icons/phone.svg";
import idIcon from "../../../assets/images/icons/id.svg";
import rankIcon from "../../../assets/images/icons/rank.svg";
import depositIcon from "../../../assets/images/icons/deposit.svg";
import transferIcon from "../../../assets/images/icons/transfer.svg";
import withdrawIcon from "../../../assets/images/icons/withdraw.svg";
import pointIcon from "../../../assets/images/icons/points.svg";
import "./SideMenu.less";
import { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { getStrMemberId } from "../../helpers/mask-label.helpers";
import { GET_CONFIGURATIONS_CONTACTUS } from "./SideMenu.gql";
import { useQuery } from "@apollo/client";
import packageJson from '../../../../package.json';
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { GET_THEME } from "../../../pages/auth/auth.gql";
const { Sider } = Layout;
const { SubMenu } = Menu;
interface Props {
	collapsed: boolean;
	setCollapse: () => void;
	showDXAChain: boolean;
	showDXAChainCallback: () => void;
	showDXAChainVideoGuide: boolean;
	showDXAChainVideoGuideCallback: () => void;
}

const SideMenu = ({ collapsed, setCollapse, showDXAChain, showDXAChainCallback, showDXAChainVideoGuide, showDXAChainVideoGuideCallback }: Props) => {
	const intl = useIntl();
	const location = useLocation();
	const { authState } = useAuth();
	const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
	const [subMenuKey, setSubMenuKey] = useState<string>();
	const [primaEmail, setPrimaEmail] = useState<string>();
	const [mt5DownloadUrl, setMT5DownloadUrl] = useState<string>("https://download.mql5.com/cdn/web/aifc.technology.ltd/mt5/aifctechnology5setup.exe");
	const { data } = useQuery(GET_CONFIGURATIONS_CONTACTUS, {
		fetchPolicy: "cache-and-network",
	});
	const { layoutState } = useLayout();
	const [logoImg, setLogoImg] = useState<string>();
    const { data: themeData } = useQuery(GET_THEME, { fetchPolicy: 'no-cache' });

	const userProfile = authState.userProfile
		? authState.userProfile
		: {
			phone: "+60112345678",
			memberId: "8888888",
			userRank: "-",
			wallets: [],
		};
	const sideNavs: any[] = [
		{ name: <FormattedMessage id="MT5_ACCOUNT" />, icon: <SolutionOutlined />, route: "/member/mt5-account" },
		// { name: <FormattedMessage id="ALL_POINTS" />, icon: <WalletOutlined />, route: "/member/wallets" },
		// {
		// 	name: <FormattedMessage id="COMPETITION" />,
		// 	icon: <TrophyOutlined />,
		// 	route: "/member/competition",
		// 	child: [
		// 		{ name: <FormattedMessage id="JOIN_COMPETITION" />, route: "/member/competition/join-competition" },
		// 		{ name: <FormattedMessage id="RANKING" />, route: "/member/competition/ranking" },
		// 	],
		// },
		{
			name: <FormattedMessage id="REPORTS" />,
			icon: <FileTextOutlined />,
			route: "/member/reports",
			child: [
				{ name: <FormattedMessage id="DEPOSIT_HISTORY" />, route: "/member/reports/deposit_history" },
				{ name: <FormattedMessage id="WITHDRAWAL_HISTORY" />, route: "/member/reports/withdrawal_history" },
				{ name: <FormattedMessage id="TRADE_HISTORY" />, route: "/member/reports/trade_history" },
				// { name: <FormattedMessage id="GROUP_INVESTMENT_BALANCE" />, route: "/member/reports/ct-group-investment-balance"},
				// { name: <FormattedMessage id="COMMISSIONS" />, route: "/member/reports/commissions" },
				// { name: <FormattedMessage id="CORPORATE_FUND_REPORT" />, route: "/member/reports/corporate-fund" },
				// { name: <FormattedMessage id="HFUND_COMMISSIONS_HISTORY" />, route: "/member/reports/hfund-commissions" },
			]
		},
		{
			name: <FormattedMessage id="ACCOUNT" />,
			icon: <UserOutlined />,
			route: "/member/account",
			child: [
				{ name: <FormattedMessage id="MY_PROFILE" />, route: "/member/account/my-profile" },
				{ name: <FormattedMessage id="KYC" />, route: "/member/account/kyc" },
				{ name: <FormattedMessage id="CHANGE_PASSWORD" />, route: "/member/account/change-password" },
				{ name: <FormattedMessage id="MY_BANK_ACCOUNT" />, route: "/member/account/bank-usdt-accounts" },
			],
		},
		// { name: <FormattedMessage id="REFER_FRIENDS" />, icon: <UserAddOutlined />, route: "/member/refer-friends" },
	];

	useEffect(() => {
		const userAgent = window.navigator.userAgent || '';

		if (userAgent.indexOf("Mac") !== -1) {
			setMT5DownloadUrl("https://download.mql5.com/cdn/mobile/mt5/ios?server=AIFCTechnology-Server");
		} else if (userAgent.indexOf("Android") !== -1) {
			setMT5DownloadUrl("https://download.mql5.com/cdn/mobile/mt5/android?server=AIFCTechnology-Server");
		} else {
			setMT5DownloadUrl("https://download.mql5.com/cdn/web/aifc.technology.ltd/mt5/aifctechnology5setup.exe");
		}
	}, []);

	useEffect(() => {
		const selectedKeys = location.pathname.split("/");
		setSelectedKey(location.pathname);
		const expandedSubMenu = selectedKeys.length > 3 ? `/member/${selectedKeys[2]}` : undefined;
		setSubMenuKey(expandedSubMenu);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	useEffect(() => {
		if (collapsed) setSubMenuKey(undefined);
		else {
			const selectedKeys = location.pathname.split("/");
			const expandedSubMenu = selectedKeys.length > 3 ? `/member/${selectedKeys[2]}` : undefined;
			setSubMenuKey(expandedSubMenu);
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collapsed]);

	useEffect(() => {
		if (data && data?.configurationsContactUs) {
			// const _primaPhone = data?.configurationsContactUs.filter((val: any) => val.id === "primaPhone");
			// setPrimaPhone(_primaPhone[0].value);
			// const _primaWhatsapp = data?.configurationsContactUs.filter((val: any) => val.id === "primaWhatsapp");
			// setPrimaWhatsapp(_primaWhatsapp[0].value);
			// const _primaWeChat = data?.configurationsContactUs.filter((val: any) => val.id === "primaWechatQR");
			// setPrimaWeChat((_primaWeChat && _primaWeChat.length > 0) ? _primaWeChat[0].value : '');
			const _primaEmail = data?.configurationsContactUs.filter((val: any) => val.id === "primaEmail");
			setPrimaEmail(_primaEmail[0].value);
			// const _primaPhoneCN = data?.configurationsContactUs.filter((val: any) => val.id === "primaPhoneCN");
			// setPrimaPhoneCN(intl.formatMessage({id: "CONTACT_US_PHONE"}, {
            //     0: _primaPhoneCN[0].value,
            //     1: 'CHI',
            // }));
			// const _primaPhoneTH = data?.configurationsContactUs.filter((val: any) => val.id === "primaPhoneTH");
			// setPrimaPhoneTH(_primaPhoneTH[0].value);
			// const _primaWechatQRTH = data?.configurationsContactUs.filter((val: any) => val.id === "primaWechatQRTH");
			// setPrimaWechatQRTH(_primaWechatQRTH[0].value);
			// const _primaLineTH = data?.configurationsContactUs.filter((val: any) => val.id === "primaLineTH");
			// setPrimaLineTH(_primaLineTH[0].value);
		}
	}, [data])

	useEffect(() => {
        if(themeData) {
            setLogoImg(themeData.theme?.logoUrl ?? SmallIcon);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeData]);

	const handleSubMenuClick = (e: any) => {
		if (!e || (e && e.length === 0)) {
			setSubMenuKey(undefined);
		} else if (e && e.length > 1) {
			setSubMenuKey(e[1]);
		} else if (e && e.length > 0) {
			setSubMenuKey(e[0]);
		}
	};

	const handleMenuItemClick = () => {
		if (window.outerWidth < 768) {
			setCollapse();
		}
	}

	const onDXAChainClick = () => {
		showDXAChainCallback();
	}

	const renderProfile = () => {
		const linkButtons = [
			{
				title: <FormattedMessage id="DEPOSIT" />,
				link: "/member/deposit",
				icon: depositIcon,
			},
			{
				title: <FormattedMessage id="TRANSFER" />,
				link: "/member/transfer",
				icon: transferIcon,
			},
			{
				title: <FormattedMessage id="WITHDRAW" />,
				link: "/member/withdraw",
				icon: withdrawIcon,
			},
		];
		const profile = [
			{
				value: `ID ${getStrMemberId(userProfile?.memberId) || "-"}`,
				icon: idIcon,
				info: null,
			},
			{
				value: userProfile?.phone || "-",
				icon: phoneIcon,
				info: null,
			},
			{
				value: `${intl.formatMessage({ id: 'MEMBER_RANK' })} ${userProfile?.userRank || "-"}`,
				icon: rankIcon,
				// info: <RankInformation />,
				info: null,
			},
			{
				value: `PP ${userProfile?.wallets?.POINT?.balance || '-'}`,
				icon: pointIcon,
				info: null,
			},
		];

		const linkButtonStyle = (link: string) => {
			if(link === '/member/deposit') return {filter: 'brightness(0.7) grayscale(100%)'};
			if (link === '/member/transfer') return { filter: 'brightness(0.7) grayscale(100%)' };
			return {};
		}

		return (
			<>
				{!collapsed && (
					<div className="profile">
						{
							profile.map((val) => {
								if (val.value.includes('PP') && (userProfile?.wallets?.POINT?.balance === undefined || userProfile?.wallets?.POINT?.balance <= 0)) {
									return false;
								}
								return (
									<div key={val.value}>
										<img src={val.icon} alt={val.icon}></img>
										<div>{val.value}</div>
										<div>{val.info || null}</div>
									</div>
								);
							})
						}
					</div>
				)}
				<div className={collapsed ? "shotcut-icon-collapse" : "shotcut-icon"}>
					{linkButtons.map((val) => {
						return (
							<Button type="text" key={val.link} disabled={val.link === '/member/deposit' || val.link === '/member/transfer'}>
								<Link to={val.link} onClick={handleMenuItemClick}>
									<div className="link-buttons">
										<img src={val.icon} alt={val.icon} style={linkButtonStyle(val.link)} ></img>
										<div className="font-caption">{val.title}</div>
									</div>
								</Link>
							</Button>
						);
					})}
				</div>
			</>
		);
	};

	const renderChildMenu = (childMenu: any[]) => {
		return childMenu.map((child) => {
			return (
				<Menu.Item key={child.route}>
					<Link to={child.route}>{child.name}</Link>
				</Menu.Item>
			);
		});
	};

	const customerService = [
		// {
		// 	value: primaPhone,
		// 	icon: <PhoneOutlined className="customer-service-icon" />,
		// },
		// {
		// 	value: (
		// 		<a href={primaWhatsapp} target="_blank" rel="noreferrer">
		// 			{primaWhatsapp}
		// 		</a>
		// 	),
		// 	icon: <WhatsAppOutlined className="customer-service-icon" />
		// },
		// {
		// 	value: (
		// 		primaWeChat ?
		// 			<>
		// 				<Button type="primary" onClick={() => setWeChatQRVisible(true)}>
		// 					<FormattedMessage id="SCAN_WECHAT_QR" />
		// 				</Button>
		// 				<Image
		// 					width={0}
		// 					style={{ display: "none" }}
		// 					preview={{
		// 						visible: weChatQRVisible,
		// 						src: primaWeChat,
		// 						onVisibleChange: value => {
		// 							setWeChatQRVisible(value);
		// 						},
		// 					}}
		// 				/>
		// 			</> :
		// 			'-'
		// 	),
		// 	icon: <WechatOutlined className="customer-service-icon" />,
		// },
		{
			value: primaEmail,
			icon: <MailOutlined className="customer-service-icon" />,
		},
	];

	const customerServiceTH = [
		// {
		// 	value: primaPhoneTH,
		// 	icon: <PhoneOutlined className="customer-service-icon" />,
		// },
		// {
		// 	value: primaLineTH,
		// 	icon: <img src={lineIcon} className="line-icon"/>
		// },
		// {
		// 	value: (
		// 		primaWechatQRTH ?
		// 			<>
		// 				<Button type="primary" onClick={() => setWeChatQRVisible(true)}>
		// 					<FormattedMessage id="SCAN_WECHAT_QR" />
		// 				</Button>
		// 				<Image
		// 					width={0}
		// 					style={{ display: "none" }}
		// 					preview={{
		// 						visible: weChatQRVisible,
		// 						src: primaWechatQRTH,
		// 						onVisibleChange: value => {
		// 							setWeChatQRVisible(value);
		// 						},
		// 					}}
		// 				/>
		// 			</> :
		// 			'-'
		// 	),
		// 	icon: <WechatOutlined className="customer-service-icon" />,
		// },
		{
			value: primaEmail,
			icon: <MailOutlined className="customer-service-icon" />,
		},
	]

	const renderCustomerService = () => {
		return (
			<div className={collapsed ? "customer-service-container-collapsed" : "customer-service-container"}>
				<Divider />
				{collapsed ? null : (
					<div className="customer-service-title">
						<FormattedMessage id="CUSTOMER_SERVICE" />
					</div>
				)}
				<div className="customer-service-subcontainer">
					{(layoutState.locale !== 'th' ? customerService: customerServiceTH).map((val, index) => {
						return (
							val.value &&
							<div key={index}>
								{val.icon}{" "}
								{collapsed ? null : <span className="customer-service-details">{val.value}</span>}
							</div>
						);
					})}
					<div className="version">
						v {packageJson.version}
					</div>
				</div>
			</div>
		);
	};

	const renderMenu = () => {
		return (
			<>
				<Divider className="side-menu-divider" />
				{renderProfile()}
				<Divider className="side-menu-divider" />
				<Menu
					className="side-menu-theme"
					theme="light"
					mode="inline"
					onClick={handleMenuItemClick}
					triggerSubMenuAction={collapsed ? "hover" : "click"}
					onOpenChange={handleSubMenuClick}
					selectedKeys={[selectedKey ? selectedKey : "/"]}
					openKeys={subMenuKey ? [subMenuKey] : undefined}
				>
					<Menu.Item key="/member" icon={<HomeOutlined />}>
						<Link className="popover-text" to="/member">
							<FormattedMessage id="GENERAL_HOME" />
						</Link>
					</Menu.Item>
					{sideNavs?.map((sideNav) => {
						return sideNav.child ? (
							<SubMenu key={sideNav.route} icon={sideNav.icon} title={sideNav.name}>
								{renderChildMenu(sideNav.child)}
							</SubMenu>
						) : (
							<Menu.Item key={sideNav.route} icon={sideNav.icon}>
								<Link className="popover-text" to={sideNav.route}>{sideNav.name}</Link>
							</Menu.Item>
						);
					})}
					<Menu.Item key="/member/download-mt4" icon={<DownloadOutlined />}>
						<a href={mt5DownloadUrl}
							target="_blank" rel="noopener noreferrer">
							<FormattedMessage id="DOWNLOAD_MT5" />
						</a>
					</Menu.Item>
					{showDXAChain &&
						<Menu.Item key="" icon={<FileOutlined />}>
							<div onClick={onDXAChainClick}>
								<FormattedMessage id="DXA_CHAIN" />
							</div>
						</Menu.Item>
					}
					<Menu.Item key="" icon={<PlaySquareOutlined />}>
						<div onClick={showDXAChainVideoGuideCallback}>
							<FormattedMessage id="DXA_CHAIN" />&nbsp;-&nbsp;
							<FormattedMessage id="VIDEO_GUIDE" />
						</div>
					</Menu.Item>
				</Menu>
			</>
		);
	};

	return (
		<>
			<Sider
				width={256}
				trigger={null}
				collapsible
				collapsed={collapsed}
				collapsedWidth={window.outerWidth < 768 ? 0 : 80}
				className="slider-container"
			>
				<div className="side-nav-background">
					<div className="side-nav-logo">
						<Link to="/member" onClick={handleMenuItemClick}>
                            <img src={logoImg} alt="logo" className={collapsed ? "logo-collapse" : "logo-default"} />
						</Link>
					</div>
					{renderMenu()}
				</div>
				{renderCustomerService()}
			</Sider>
		</>
	);
};

export default SideMenu;
