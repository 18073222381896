export enum EAMasterTypeEnum {
    FOREIGN_EXCHANGE = 'EA_FOREIGN_EXCHANGE_MASTER',
    INDICES = 'EA_INDICES_MASTER',
    COMMODITIES = 'EA_COMMODITIES_MASTER',
    PAMM = 'EA_PAMM_ACCOUNT'
}

export const matchEAWithMasterType = (eaId: string) => {
    switch (eaId) {
        case 'ckp7vbsky000201l0e9bm10si':
            return EAMasterTypeEnum.FOREIGN_EXCHANGE;
        case 'ckp7vbqre000101l0dx5ybc11':
            return EAMasterTypeEnum.INDICES;
        // case 'ckp7vbheb000001l0dfij5kj7':
        //     break;
        case 'ckxfo6w0q00041bn62dsw0pe0':
            return EAMasterTypeEnum.INDICES;
        case 'ckxfo8qzd00051bn6dwc15wop':
            return EAMasterTypeEnum.COMMODITIES
        case 'ckxfo9hpq00061bn6dhkqhttd':
            return EAMasterTypeEnum.FOREIGN_EXCHANGE;
        case 'cl8cjzma300040ajsaxlg82cw':
            return EAMasterTypeEnum.PAMM;
        default:
            return EAMasterTypeEnum.FOREIGN_EXCHANGE;
    }
}