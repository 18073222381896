import Marquee from "react-fast-marquee";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { setDBLanguageToGet } from "../../../shared/helpers/language.helpers";
import "./Alerts.less";

const MarqueeComponent = ({ marqueeData }: any) => {
	const { layoutState } = useLayout();

    return (
        <Marquee 
            speed={40} 
            pauseOnHover 
            gradientWidth={170} 
            gradientColor={[241, 216, 175]} 
            >
            {marqueeData?.announcement?.message[setDBLanguageToGet(layoutState.locale)]}
        </Marquee>
    )
}

export default MarqueeComponent;