import React, { createContext, useContext, useReducer } from "react";

interface SocketState {
    socket: any
}

const INITIAL_STATE: SocketState = {
    socket: null
};

export const SET_SOCKET_CLIENT = "[SOCKET] SET_SOCKET_CLIENT";
export const CLEAR_SOCKET_CLIENT = "[SOCKET] CLEAR_SOCKET_CLIENT";

const reducer = (state: SocketState, action: { type: string, payload: any }): SocketState => {
    const { type, payload } = action;
    switch (type) {
        case SET_SOCKET_CLIENT:
            return {
                socket: payload.socket
            }
        case CLEAR_SOCKET_CLIENT:
            return INITIAL_STATE;
        default:
            return state;
    }
};

const socketContext = createContext<{ socketState: SocketState; socketDispatch: React.Dispatch<any>; }>
    ({ socketState: INITIAL_STATE, socketDispatch: () => null });

export const SocketProvider = ({ children }: any) => {
    const [socketState, socketDispatch] = useReducer(reducer, INITIAL_STATE);
    return (
        <socketContext.Provider value={{ socketState, socketDispatch }} >
            {children}
        </socketContext.Provider>
    );
}

export const useSocket = () => {
    return useContext(socketContext);
};