import React, { createContext, useContext, useReducer } from "react";

interface RankState {
    strMemberId: string,
    walletBalance: number,
    mt4AccountBalance: number,
    grandTotalBalance: number,
    totalIBDownlineMember: number,
    totalSIBDownlineMember: number,
    totalMIBDownlineMember: number,
    totalPIBDownlineMember: number,
    totalDIBDownlineMember: number,
    totalDownlineSales: number,
    totalThreeLvlSales: number,
    totalFiveLvlSales: number,
    totalDirectDL10kSalesWithoutSIB: number,
    totalDirectDL40kSalesWithoutMIB: number,
    currentRank: string,
};

const INITIAL_STATE: RankState = {
    strMemberId: "",
    walletBalance: 0,
    mt4AccountBalance: 0,
    grandTotalBalance: 0,
    totalIBDownlineMember: 0,
    totalSIBDownlineMember: 0,
    totalMIBDownlineMember: 0,
    totalPIBDownlineMember: 0,
    totalDIBDownlineMember: 0,
    totalDownlineSales: 0,
    totalThreeLvlSales: 0,
    totalFiveLvlSales: 0,
    totalDirectDL10kSalesWithoutSIB: 0,
    totalDirectDL40kSalesWithoutMIB: 0,
    currentRank: "",
};

export const SET_RANK_INFO = "[RANK] SET_RANK_INFO";

const rankReducer = (state: RankState, action: { type: string, payload: any }): RankState => {
    const { type, payload } = action;
    switch (type) {
        case SET_RANK_INFO:
            return {
                ...state,
                ...payload['getUserRankUpgradeInfo'],
            }
        default:
            return state;
    }
};

const rankContext = createContext<{ rankState: RankState; rankDispatch: React.Dispatch<any>; }>
    ({ rankState: INITIAL_STATE, rankDispatch: () => null });

export const RankProvider = ({ children }: any) => {
    const [rankState, rankDispatch] = useReducer(rankReducer, INITIAL_STATE);
    return (
        <rankContext.Provider value={{ rankState, rankDispatch }} >
            {children}
        </rankContext.Provider>
    );
}

export const useRank = () => {
    return useContext(rankContext);
}