import { Alert, Col, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { getEligibleRanks } from "../../../shared/helpers/mask-label.helpers";
import "./Alerts.less";

interface Props {
    handlePinClick: () => void;
    handleCorporateFundClick: () => void;
    hasUnreadCorporateFundTNC: boolean;
}

const AlertComponent = ({ handlePinClick, handleCorporateFundClick, hasUnreadCorporateFundTNC }: Props) => {
    const { authState } = useAuth();
    return (
        <Row gutter={[8, 8]} align="middle" className="profile-alert">
            {authState.userProfile?.kycStatus === 'PENDING' || authState.userProfile?.kycStatus === 'APPROVED' ? null : (
                <Col>
                    <Link to="/member/account/kyc">
                        <Alert
                            className="cursor-pointer"
                            message={
                                <>
                                    <FormattedMessage id={authState.userProfile?.kycStatus === "REJECTED" ? "KYC_REJECTED" : "REMIND_KYC"} />
                                </>
                            }
                            type={authState.userProfile?.kycStatus === "REJECTED" ? "error" : "warning"}
                            showIcon
                        />
                    </Link>
                </Col>
            )}
            {authState.userProfile?.pinStatus ? null : (
                <Col>
                    <Alert
                        className="cursor-pointer"
                        onClick={handlePinClick}
                        message={
                            <>
                                <FormattedMessage id="REMIND_PIN" />
                            </>
                        }
                        type="warning"
                        showIcon
                    />
                </Col>
            )}
            {authState.userProfile?.emailVerified ? null : (
                <Col>
                    <Link to="/member/account/my-profile">
                        <Alert
                            className="cursor-pointer"
                            message={
                                <>
                                    <FormattedMessage id="REMIND_EMAIL" />
                                </>
                            }
                            type="warning"
                            showIcon
                        />
                    </Link>
                </Col>
            )}
            {
                getEligibleRanks(authState.userProfile?.userRank ?? '', 'CORPORATE_FUND') && hasUnreadCorporateFundTNC && <Col>
                    <Alert
                        className="cursor-pointer"
                        onClick={handleCorporateFundClick}
                        message={
                            <>
                                <FormattedMessage id="CORPORATE_FUND_TNC" />
                            </>
                        }
                        type="warning"
                        showIcon
                    />
                </Col>
            }
        </Row>
    );
}

export default AlertComponent;