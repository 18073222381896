import { Button } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FormattedMessage } from "react-intl";
import { setGeneralCookie } from "../../../shared/helpers/set-general-cookie.helper";
import { VerificationType } from "./VerificationType.enum";
interface Props {
    type: VerificationType;
    hasError: boolean;
    isSuccess?: boolean;
    requestStatus: () => void;
    isPinMatched: boolean;
}
const CodeRequestButton = ({ type, requestStatus, hasError, isSuccess = false, isPinMatched = false }: Props) => {
    // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies();
    const [cooldown, setCooldown] = useState<number>(0);
    const [cooldownTimer, setCooldownTimer] = useState<number>();

    useEffect(() => {
        console.log('test modal load');
        const cooldownFromCookie = cookies[`c-cooldown-${type.toString()}`];
        const cooldownTIme = cookies[`c-cooldown-start-${type.toString()}`] ? +cookies[`c-cooldown-start-${type.toString()}`]: 179;

        if (cooldownFromCookie) {
            const timePassed = moment().unix() - (+cooldownFromCookie);
            //due to latency, reduce 1 sec
            const remainingSeconds = timePassed > +cooldownTIme ? 0 : (+cooldownTIme - timePassed);
            setCooldown(remainingSeconds);
            initiateTimer(remainingSeconds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const clearTimer = () => {
            removeCookie(`c-cooldown-${type.toString()}`);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
        }
        return () => clearTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cooldownTimer])

    useEffect(() => {
        if (hasError || isSuccess) {
            removeCookie(`c-cooldown-${type.toString()}`);
            setCooldown(0);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasError || isSuccess]);

    const initiateTimer = (amount: number) => {
        const cooldownInterval: number = window.setInterval(() => {
            if (amount > 0) {
                setCooldown(amount--);
            } else {
                setCooldown(0);
                clearInterval(cooldownInterval);
            }
        }, 1000);
        setCooldownTimer(cooldownInterval);
    }

    const requestCode = () => {
        if (cooldown === 0) {
            //1 minute cooldown
            setGeneralCookie(`c-cooldown-${type.toString()}`, moment().unix().toString(), 179);
            setCooldown(179);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
            initiateTimer(179);
            requestStatus();
        }
    }


    return (
        <Button disabled={cooldown > 0 || !isPinMatched} onClick={requestCode}>
            {(!cooldown || cooldown === 0) ? <FormattedMessage id="AUTH_REQUEST_CODE" />
                : (cooldown > 1 ? <FormattedMessage id="AUTH_COOL_DOWNS" values={{ cooldown }} />
                    : <FormattedMessage id="AUTH_COOL_DOWN" values={{ cooldown }} />)}
        </Button>)

}

export default CodeRequestButton;