import { useMutation, useQuery } from "@apollo/client";
import { Typography, Button, Form, Alert, Input, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import loginBox from '../../assets/images/celebration/login.png';
import logo from '../../assets/images/celebration/logo.svg';
import LanguageSwitcher from "../../shared/widgets/language-switcher/LanguageSwitcher";
import { FORGOT_PASSWORD, GET_THEME } from "./auth.gql";
import './Auth.less';
const { Title } = Typography;

const ForgotPassword = () => {
    const [form] = Form.useForm();
    const [error, setError] = useState<string>();
    const [success, setSucess] = useState<string>();
    const [loginBoxImg, setLoginBoxImg] = useState<any>({ background: `url(${loginBox}) 0% 0% / cover` });
    const [logoImg, setLogoImg] = useState<string>(logo);
    const intl = useIntl();
    const [forgotPassword, { data, loading, error: forgotPasswordError }] = useMutation(FORGOT_PASSWORD, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const memberIdPrefix = process.env.REACT_APP_MEMBERID_PREFIX ? process.env.REACT_APP_MEMBERID_PREFIX : '';
    const { data: themeData } = useQuery(GET_THEME, { fetchPolicy: 'no-cache' });

    const onFinish = (values: any) => {
        forgotPassword({
            variables: {
                email: values.email,
                strMemberId: values.strMemberId,
            }
        });
    };

    useEffect(() => {
        if (forgotPasswordError) {
            setSucess('');
            if (forgotPasswordError.message?.indexOf('DATA_NOT_EXISTED') > -1) {
                setError('AUTH_ERROR_EMAIL_NOT_EXIST');
            } else if (forgotPasswordError.message?.indexOf('INVALID_INFO') > -1) {
                setError('AUTH_ERROR_EMAIL_NOT_VERIFIED');
            }
        }
        else if (data) {
            if (data.forgotPassword.status === 'SUCCESS') {
                setSucess('AUTH_FORGOT_PASSWORD_SUCCESS');
                setError('');
            } else {
                if (data.forgotPassword.status === 'INVALID_INFO') {
                    setError('AUTH_ERROR_EMAIL_NOT_VERIFIED');
                } else {
                    setError('AUTH_ERROR_EMAIL_NOT_EXIST');
                }
            }
        }
    }, [data, forgotPasswordError])

    useEffect(() => {
        if (themeData && themeData.theme) {
            setLoginBoxImg({ background: `url(${themeData.theme?.loginBoxUrl ?? loginBox}) 0% 0% / cover` });
            setLogoImg(themeData.theme?.logoUrl ?? logo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeData]);

    return (
        <>
            <div className="cover-container-phone">
                <img src={logoImg} alt="herzen-logo" className="default-logo" height={!themeData?.theme ? '150' : ''} />
            </div>
            <div className="auth-container">
                <div className="cover-container" style={{ ...loginBoxImg }}>
                    <Title level={1}><FormattedMessage id="AUTH_WELCOME" /></Title>
                    <img src={logoImg} alt="herzen-logo" className="default-logo" height={!themeData?.theme ? '150' : ''} />
                </div>
                <div>
                    <div className="auth-title">
                        <Title level={4}>
                            <FormattedMessage id="AUTH_FORGOT_PASSWORD" />
                        </Title>
                        <LanguageSwitcher />
                    </div>
                    <div className="auth-content">
                        <Form
                            name="form"
                            form={form}
                            onFinish={onFinish}
                        >
                            {error && <Alert
                                className="auth-error"
                                message={<FormattedMessage id={error} />}
                                type="error"
                                showIcon
                            />}
                            {success && <Alert
                                className="auth-error"
                                message={<FormattedMessage id={success} />}
                                type="success"
                                showIcon
                            />}
                            <Row className="row-margin">
                                <Col flex="1">
                                    <FormattedMessage id="MY_PROFILE_MEMBERID" /></Col>
                                <Col flex="2.5">
                                    <Form.Item
                                        name="strMemberId"
                                        required className="need-require"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: 'LOGIN_MEMBERID_REQUIRED' }) },
                                            { min: memberIdPrefix.length + 5, message: intl.formatMessage({ id: 'LOGIN_MEMBERID_VALIDATE' }) }]}>
                                        <Input maxLength={10} placeholder={intl.formatMessage({ id: 'AUTH_REFERRAL_ID_SAMPLE' })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-margin">
                                <Col flex="1">
                                    <FormattedMessage id="MY_PROFILE_EMAIL" /></Col>
                                <Col flex="2.5">
                                    <Form.Item name="email" required className="need-require"
                                        rules={[
                                            { required: true, message: intl.formatMessage({ id: 'MY_PROFILE_EMAIL_REQUIRE' }) },
                                            { type: "email", message: intl.formatMessage({ id: 'MY_PROFILE_EMAIL_TYPE_INVALID' }) },
                                        ]}>
                                        <Input placeholder={intl.formatMessage({ id: 'MY_PROFILE_EMAIL' })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item className="auth-button">
                                {!success ? <Button block type="primary" htmlType="submit" loading={loading}>
                                    <FormattedMessage id="AUTH_FORGOT_PASSWORD" />
                                </Button> :
                                    <Link to="/auth">
                                        <Button block type="primary">
                                            <FormattedMessage id="RETURN_TO_SIGN_IN" />
                                        </Button>
                                    </Link>}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="forgot-password-container">
                        <div>
                            <span>
                                <FormattedMessage id="AUTH_LOGIN_INTRO" />
                            </span>
                            <Link to="/auth">
                                <Button type="link" size="small">
                                    <FormattedMessage id="AUTH_LOGIN_TITLE" />
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
