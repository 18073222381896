import { gql } from '@apollo/client';

export const GET_EXPERT_ADVISORS = gql`
query ExpertAdvisors {
    expertAdvisors {
        id,
        mt4Id,
        name,
        photoUrl,
        lotSize,
        roi,
        lastMonth,
        performanceMonth,
        fxBookUrl
    }
}
`;

export const GET_PROFILE = gql`
query Profile {
    profile {
        MT4Amount,
        MT4AllTradeBalance,
        MT4LatestTransfer,
        MT4LatestWithdrawal,
        WalletLatestWithdrawal,
        WalletLatestComission,
        TeamMembers,
        hasSelfTradeAccount,
        WPointLastAllocation,
        RPointLastWithdrawal
    }
}
`;

export const GET_BANNER = gql`
query Banners{
    banners {
            id,
            title,
            pictureUrl,
    }
}
`;

export const GET_TOP_NEWS = gql`
query TopNews{
    topNews {
            id,
            title,
            pictureUrl,
            createdAt,
    }
  }
`;

export const GET_NEWSLIST = gql`
query NewsList($pageNo:Int,$pageSize:Int!){
    newsList(pageNo:$pageNo, pageSize:$pageSize){
        count,
        newsList {
            id,
            title,
            content,
            pictureUrl,
            createdAt,
        }
    }
  }
`;

export const GET_NEWS = gql`
query News($id:ID!){
    news(id:$id){
            id,
            title,
            content,
            pictureUrl,
            createdAt,
        }
  }
`;

export const GET_ACTIVE_PARTICIPANTS = gql`
query ActiveCompetition{
    activeCompetition{
        liteCompetition {
            id,
            userId,
            competitionId,
            rank,
            mt4AccountId,
            balance,
            equity,
            user {
                name,
            }
        },
        liteCompetitionCurrentUser {
            id,
            userId,
            competitionId,
            rank,
            mt4AccountId,
            balance,
            equity,
            user {
                name,
            }
        },
        proCompetition {
            id,
            userId,
            competitionId,
            rank,
            mt4AccountId,
            balance,
            equity,
            user {
                name,
            }
        },
        proCompetitionCurrentUser {
            id,
            userId,
            competitionId,
            rank,
            mt4AccountId,
            balance,
            equity,
            user {
                name,
            }
        },
    }
  }
`;

export const GET_ANNOUNCEMENT = gql`
query Announcement{
    announcement {
            message
    }
  }
`;

export const GET_RANK_INFO = gql`
query GetUserRankUpgradeInfo {
    getUserRankUpgradeInfo {
    strMemberId,
    walletBalance,
    mt4AccountBalance,
    grandTotalBalance,
    totalIBDownlineMember,
    totalSIBDownlineMember,
    totalMIBDownlineMember,
    totalPIBDownlineMember,
    totalDIBDownlineMember,
    totalThreeLvlSales,
    totalFiveLvlSales,
    totalDirectDL10kSalesWithoutSIB,
    totalDirectDL40kSalesWithoutMIB,
    currentRank,
    }
}
`;

export const SET_PASSWORD = gql`
mutation SetPassword($password:String!){
    setPassword(password:$password){
        status,
        a,
    }
}
`;

export const GET_UNREAD_POPUP_ANNOUNCEMENT = gql`
query PopUpUnreadAnnouncement{
    popUpUnreadAnnouncement {
        id,
        message
    }
}
`;

export const INSERT_SEEN_ANNOUNCEMENT = gql`
mutation InsertSeenAnnouncement($announcementId:String!){
    insertSeenAnnouncement(announcementId:$announcementId){
        status,
        a,
    }
}
`;

export const REGISTER_SUCCESS_VERIFY_EMAIL = gql`
    mutation RegisterSuccessVerifyEmail($verificationCode: String!, $email: String!, $preferredEmailLanguage: String!){ 
        registerSuccessVerifyEmail(verificationCode: $verificationCode, email:$email, preferredEmailLanguage: $preferredEmailLanguage){ 
            status, id 
        } 
    }
`;

export const INSERT_AGREED_TNC = gql`
mutation InsertAgreedTNC($tncId: String!){
    insertAgreedTNC(tncId: $tncId){
        status,
        a,
    }
}
`;

export const GET_AVAILABLE_TNC_BY_TYPE = gql`
query TncByType($tncType:String!){
    tncByType(tncType:$tncType) {
        tnc{
            id,
            version,
            tncType,
            isMandatory,
            title,
            content,
            isActive
        },
        tNCDocument{
            documentUrl,
            documentCategory
        }
    }
  }
`;

export const UPDATE_POINTAGREEMENT = gql`
mutation updatePointAgreement{
    updatePointAgreement{
        status,
        a,
    }
}
`;

export const ADD_CRYPTO_WALLET = gql`
mutation AddCryptoWallet($cryptoWalletAddInput: CryptoWalletAddInput!){
    addCryptoWallet(cryptoWalletAddInput: $cryptoWalletAddInput){
        status,
        a
    }
}
`;


export const GET_CRYPTO_WALLET = gql`
query CryptoWallet{
    cryptoWallet{
            id,
            userId,
            userName
            rPoint,
            wPoint,
            status,
            walletAddress,
            createdAt,
            updatedAt
        }
    }
`;