import './Auth.less';
import { FormattedMessage } from 'react-intl';
import { Typography, Tabs, Button } from 'antd';
import LanguageSwitcher from '../../shared/widgets/language-switcher/LanguageSwitcher';
import loginBox from '../../assets/images/celebration/login.png';
import logo from '../../assets/images/celebration/logo.svg';
import LoginPasswordForm from './LoginPasswordForm';
import SMSForm from './SMSForm';
import { Link } from 'react-router-dom';
import { useLayout } from '../../hooks/layout/LayoutContext';
import { GET_THEME } from '../auth/auth.gql';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { db } from "../../shared/helpers/firebase.helper";
import { onValue, ref } from "firebase/database";
const { Title } = Typography;
const { TabPane } = Tabs;

const Login = () => {
    const { layoutState } = useLayout();
    const [loginBoxImg, setLoginBoxImg] = useState<any>({ background: `url(${loginBox}) 0% 0% / cover` });
    const [logoImg, setLogoImg] = useState<string>(logo);
    const { data: themeData } = useQuery(GET_THEME, { fetchPolicy: 'no-cache' });
    const [maintenanceData, setMaintenanceData] = useState<any>({});

    useEffect(() => {
        const query: any = ref(db, "messages/system-maintenance");
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setMaintenanceData(data);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (themeData && themeData.theme) {
            setLoginBoxImg({ background: `url(${themeData.theme?.loginBoxUrl ?? loginBox}) 0% 0% / cover` });
            setLogoImg(themeData.theme?.logoUrl ?? logo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeData]);

    return (
        <>
            <div className="cover-container-phone">
                <img src={logoImg} alt="herzen-logo" className="default-logo" height={!themeData?.theme ? '150' : ''} />
            </div>

            <div className="auth-container">
                <div className="cover-container" style={{ ...loginBoxImg }}>
                    <Title level={1}><FormattedMessage id="AUTH_WELCOME" /></Title>
                    <img src={logoImg} alt="herzen-logo" className="default-logo" height={!themeData?.theme ? '150' : ''} />
                </div>
                <div>
                    <div className={layoutState.locale === 'ms' ? "auth-title-long" : "auth-title"}>
                        <Title level={4}><FormattedMessage id="AUTH_LOGIN_TITLE" /></Title>
                        <LanguageSwitcher />
                    </div>
                    <Tabs defaultActiveKey="1" className="auth-content">
                        <TabPane tab={<FormattedMessage id="AUTH_PASSWORD_LOGIN_TITLE" />} key="1">
                            <LoginPasswordForm maintenanceData={maintenanceData} />
                        </TabPane>
                        <TabPane tab={<FormattedMessage id="AUTH_SMS_LOGIN_TITLE" />} key="2">
                            <SMSForm maintenanceData={maintenanceData} fromReferralLink={false} />
                        </TabPane>
                    </Tabs>
                    <div className="forgot-password-container">
                        <div>
                            {/* <span><FormattedMessage id="AUTH_SIGNUP_INTRO" /></span>
                            <Link to="/auth/register">
                                <Button type="link" size="small"><FormattedMessage id="AUTH_SIGNUP_LINK" /></Button>
                            </Link> */}
                        </div>
                        <Link to="/auth/forgot-password">
                            <Button type="link" size="small"><FormattedMessage id="AUTH_FORGOT_PASSWORD" /></Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>)
}

export default Login