import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
	path: string;
	component: any;
	routes?: RouteInfo[];
	lazy?: boolean;
	protected?: boolean;
}

const ReactLazyPreload = (importStatement: any) => {
	const Component: any = React.lazy(importStatement);
	Component.preload = importStatement;
	return Component;
};

const MyProfile = ReactLazyPreload(() => import("./pages/account/my-profile/MyProfile"));
MyProfile.preload();

const ChangePassword = ReactLazyPreload(() => import("./pages/account/change-password/ChangePassword"));
ChangePassword.preload();

const Wallets = ReactLazyPreload(() => import("./pages/wallets/Wallets"));
Wallets.preload();

const WalletInformation = ReactLazyPreload(() => import("./pages/wallets/WalletInformation"));
WalletInformation.preload();

const Deposit = ReactLazyPreload(() => import("./pages/deposit/Deposit"));
Deposit.preload();

const Transfer = ReactLazyPreload(() => import("./pages/transfer/Transfer"));
Transfer.preload();

const PaymentTransfer = ReactLazyPreload(() => import("./pages/transfer/PaymentTransfer"));
PaymentTransfer.preload();

const Withdraw = ReactLazyPreload(() => import("./pages/withdraw/Withdraw"));
Withdraw.preload();

const Kyc = ReactLazyPreload(() => import("./pages/account/kyc/Kyc"));
Kyc.preload();

const BankUsdtAccount = ReactLazyPreload(() => import("./pages/account/bank-usdt-accounts/BankUsdtAccounts"));
BankUsdtAccount.preload();

const BankUsdtForm = ReactLazyPreload(() => import("./pages/account/bank-usdt-accounts/BankUsdtForm"));
BankUsdtForm.preload();

const BankUsdtFormEdit = ReactLazyPreload(() => import("./pages/account/bank-usdt-accounts/BankUsdtFormEdit"));
BankUsdtFormEdit.preload();

const WithdrawalHistory = ReactLazyPreload(() => import("./pages/reports/withdrawal-history/WithdrawalHistory"));
WithdrawalHistory.preload();

const DepositHistory = ReactLazyPreload(() => import("./pages/reports/deposit-history/DepositHistory"));
DepositHistory.preload();

const TradeHistory = ReactLazyPreload(() => import("./pages/reports/trade-history/TradeHistory"));
TradeHistory.preload();

const CPGroupInvestmentBalance = ReactLazyPreload(
	() => import("./pages/reports/cp-group-investment-balance/CPGroupInvestmentBalance")
);
CPGroupInvestmentBalance.preload();

const Commissions = ReactLazyPreload(() => import("./pages/reports/commissions/Commissions"));
Commissions.preload();

const NewsList = ReactLazyPreload(() => import("./pages/home/news/NewsList"));
NewsList.preload();

const mt4Account = ReactLazyPreload(() => import("./pages/account/mt4-account/MT4Account"));
mt4Account.preload();

//disable self-trade in herzen
// const mt4AccountCreate = ReactLazyPreload(() => import("./pages/account/mt4-account/MT4AccountForm"));
// mt4AccountCreate.preload();

// const CompetitionRanking = ReactLazyPreload(() => import("./pages/competition/competition-ranking/CompetitionRanking"));
// CompetitionRanking.preload();

// const CompetitionList = ReactLazyPreload(() => import("./pages/competition/competition-list/CompetitionList"));
// CompetitionList.preload();

// const CompetitionJoin = ReactLazyPreload(() => import("./pages/competition/competition-join/CompetitionJoin"));
// CompetitionJoin.preload();

const MyTeam = ReactLazyPreload(() => import("./pages/my-team/MyTeam"));
MyTeam.preload();

const News = ReactLazyPreload(() => import("./pages/home/news/News"));
News.preload();

const WalletStatement = ReactLazyPreload(() => import("./pages/wallets/WalletStatement"));
WalletStatement.preload();

const MT4WalletStatement = ReactLazyPreload(() => import("./pages/account/mt4-account/MT4WalletStatement"));
MT4WalletStatement.preload();

const MT4CompetitionWalletStatement = ReactLazyPreload(() => import("./pages/account/mt4-account/MT4CompetitionWalletStatement"));
MT4CompetitionWalletStatement.preload();

const PointWalletStatement = ReactLazyPreload(() => import("./pages/wallets/PointWalletStatement"));
PointWalletStatement.preload();

const CopyTrade = ReactLazyPreload(() => import("./pages/home/copy-trade/CopyTrade"));
CopyTrade.preload();

const Notification = ReactLazyPreload(() => import("./pages/notification/Notification"));
Notification.preload();

const RankComparison = ReactLazyPreload(() => import("./pages/home/rank-comparison-list/RankComparison"));
RankComparison.preload();

const RewardWalletInformation = ReactLazyPreload(() => import("./pages/wallets/CorporateFundInformation"));
RewardWalletInformation.preload();

const CorporateFundStatement = ReactLazyPreload(() => import("./pages/wallets/CorporateFundStatement"));
CorporateFundStatement.preload();

const CorporateFundReport = ReactLazyPreload(() => import("./pages/reports/corporate-fund/CorporateFund"));
CorporateFundReport.preload();

const HFundCommissions = ReactLazyPreload(() => import("./pages/reports/hfund-commissions/HFundCommissions"));
HFundCommissions.preload();

const ReferFriends = ReactLazyPreload(() => import("./pages/refer-friends/ReferFriends"));
ReferFriends.preload();

const Allocate = ReactLazyPreload(() => import("./pages/allocate/Allocate"));
Allocate.preload();

const PointDetails = ReactLazyPreload(() => import("./pages/point-details/PointDetails"));
PointDetails.preload();

export const routes: RouteInfo[] = [
	{
		path: "/account/my-profile",
		component: MyProfile,
		protected: true,
	},
	{
		path: "/account/change-password",
		component: ChangePassword,
		protected: true,
	},
	{
		path: "/account/kyc",
		component: Kyc,
	},
	// {
	// 	path: "/deposit",
	// 	component: Deposit,
	// 	protected: true,
	// },
	// {
	// 	path: "/transfer",
	// 	component: Transfer,
	// 	protected: true,
	// },
	{
		path: "/payment-transfer",
		component: PaymentTransfer,
		protected: true,
	},
	{
		path: "/withdraw",
		component: Withdraw,
		protected: true,
	},
	{
		path: "/news/:id",
		component: News,
		protected: true,
	},
	{
		path: "/news",
		component: NewsList,
		protected: true,
	},
	{
		path: "/wallets/my-wallet/:id",
		component: WalletInformation,
		protected: true,
	},
	{
		path: "/wallets/corporate-fund/:id",
		component: RewardWalletInformation,
		protected: true,
	},
	{
		path: "/wallets/mt5-wallet/:id",
		component: WalletInformation,
		protected: true,
	},
	{
		path: "/wallets/mt5-competition-wallet/:id",
		component: WalletInformation,
		protected: true,
	},
	// {
	// 	path: "/wallets",
	// 	component: Wallets,
	// 	protected: true,
	// },
	{
		path: "/reports/deposit_history",
		component: DepositHistory,
		protected: true,
	},
	{
		path: "/reports/withdrawal_history",
		component: WithdrawalHistory,
		protected: true,
	},
	{
		path: "/reports/trade_history/:type",	
		component: TradeHistory,		
		protected: true,	
		},
	{
		path: "/reports/trade_history",
		component: TradeHistory,
		protected: true,
	},
	// {
	// 	path: "/reports/ct-group-investment-balance",
	// 	component: CPGroupInvestmentBalance,
	// 	protected: true
	// },
	// {
	// 	path: "/reports/commissions",
	// 	component: Commissions,
	// 	protected: true
	// },
	// {
	// 	path: "/reports/corporate-fund",
	// 	component: CorporateFundReport,
	// 	protected: true
	// },
	// {
	// 	path: "/reports/hfund-commissions",
	// 	component: HFundCommissions,
	// 	protected: true
	// },
	{
		path: "/account/bank-usdt-accounts/form/:id",
		component: BankUsdtFormEdit,
		protected: true,
	},
	{
		path: "/account/bank-usdt-accounts/form",
		component: BankUsdtForm,
		protected: true,
	},
	{
		path: "/account/bank-usdt-accounts",
		component: BankUsdtAccount,
		protected: true,
	},
	// {
	// 	path: "/mt5-account/create",
	// 	component: mt4AccountCreate,
	// 	protected: true,
	// },
	{
		path: "/mt5-account",
		component: mt4Account,
		protected: true,
	},
	// {
	// 	path: "/competition/ranking",
	// 	component: CompetitionRanking,
	// 	protected: true,
	// },
	// {
	// 	path: "/competition/join-competition/:id",
	// 	component: CompetitionJoin,
	// 	protected: true,
	// },
	// {
	// 	path: "/competition/join-competition",
	// 	component: CompetitionList,
	// 	protected: true,
	// },
	//#Temporary commented#
	// {
	// 	path: "/my-team",
	// 	component: MyTeam,
	// 	protected: true,
	// },
	{
		path: "/wallet-statement/:id",
		component: WalletStatement,
		protected: true,
	},	
	{
		path: "/point-wallet-statement/:id",
		component: PointWalletStatement,
		protected: true,
	},	
	{
		path: "/corporate-fund-statement/:id",
		component: CorporateFundStatement,
		protected: true,
	},
	{
		path: "/mt5-competition-statement/:id",
		component: MT4CompetitionWalletStatement,
		protected: true,
	},
	{
		path: "/mt5-statement/:id",
		component: MT4WalletStatement,
		protected: true,
	},
	{
		path: "/mt5-comstatement/:id",
		component: MT4WalletStatement,
		protected: true,
	},
	{
		path: "/copy-trade/:id",
		component: CopyTrade,
		protected: true,
	},
	{
		path: "/copy-trade-invest/:id",
		component: CopyTrade,
		protected: true,
	},
	{
		path: "/notification",
		component: Notification,
		protected: true,
	},
	{
		path: "/rankcomparison",
		component: RankComparison,
		protected: true,
	},
	// {
	// 	path: "/refer-friends",
	// 	component: ReferFriends,
	// 	protected: true,
	// },
	// Temporary comment 21/5/2023
	// {
	// 	path: "/allocate/wpoint",
	// 	component: Allocate,
	// 	protected: true,
	// },
	{
		path: "/r-point-detail",
		component: PointDetails,
		protected: true,
	},
	{
		path: "/w-point-detail",
		component: PointDetails,
		protected: true,
	},
	{
		path: "*",
		component: NoMatch,
	},
];
