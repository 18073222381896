export default class ApiHelper {
    static endpoint = process.env.REACT_APP_QR_PAYMENT_API ; 

    static getApiHeader(apiMethod: string, url: string, body: any, type: string): Request {
        var token = localStorage.getItem('token') !== null ? localStorage.getItem('token') : ''
        var returnBody = body ? JSON.stringify(body) : undefined
        if (type === 'formData') {
            returnBody = body ? body : undefined
            return new Request(this.endpoint + url, {
                method: apiMethod,
                // mode: 'no-cors',
                body: returnBody,
                headers: new Headers({
                    'Auth': token ? token : '',
                }),
            });
        }
        return new Request(this.endpoint + url, {
            method: apiMethod,
            body: returnBody,
            // mode: 'no-cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Auth': token ? token : '',
                'Content-Type': 'application/json'
            }),
        });
    }

    static getMethod(url: string, onSuccess: any, onError: any) {
        fetch(this.getApiHeader('GET', url, undefined, 'json')).then(response => {
            return response.json();
        })
            .then(data => onSuccess(data))
            .catch(error => onError(error))
    }

    static postMethod(url: string, body: any, onSuccess: any, onError: any) {
        fetch(this.getApiHeader('POST', url, body, 'json')).then(response => {
            return response.json();
        })
            .then(data => onSuccess(data))
            .catch(error => onError(error))
    }

    static patchMethod(url: string, body: any, onSuccess: any, onError: any) {
        return fetch(this.getApiHeader('PATCH', url, body, 'json')).then(response => {
            return response.json();
        })
            .then(data => onSuccess(data))
            .catch(error => onError(error))
    }

    static deleteMethod(url: string, body: any, onSuccess: any, onError: any) {
        return fetch(this.getApiHeader('DELETE', url, body, 'json')).then(response => {
            return response.json();
        })
            .then(data => onSuccess(data))
            .catch(error => onError(error))
    }
    static postFormDataMethod(url: string, body: any, onSuccess: any, onError: any) {
        fetch(this.getApiHeader('POST', url, body, 'formData')).then(response => {
            return response.json();
        })
            .then(data => onSuccess(data))
            .catch(error => onError(error))
    }

}