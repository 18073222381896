import { ApolloError, useMutation } from "@apollo/client";
import { Button, Form, Input, Modal } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import useNotification from "../../../hooks/layout/useNotification";
import PinCodeInput from "../../../shared/components/form-input/PinCodeInput";
import { setGeneralCookie } from "../../../shared/helpers/set-general-cookie.helper";
import CodeRequestButton from "./CodeRequestButton";
import { GET_CODE } from "./my-profile.gql";
import { VerificationType } from "./VerificationType.enum";

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
    displayMsg: { title: string | undefined, type: string, submitBtnTitle: string | undefined, attachedText: string | undefined }
    submitPin: { callFunction: any, callData: any, callError: ApolloError | undefined }
    canClose: boolean | undefined;
}

const MyProfilePinDialog = ({ displayMsg, modalVisible, submitPin, canClose = true }: Props) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [resetVerificationRequest, setResetVerificationRequest] = useState<boolean>(false);
    const [requestPin, { error: requestPinError }] = useMutation(GET_CODE, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [isPinMatched, setIsPinMatched] = useState<boolean>(false);

    const handleCancel = () => {
        modalVisible.setVisible(!canClose);
    };

    useEffect(() => {
        if (submitPin.callError) {
            setErrorNotification(intl.formatMessage({ id: 'PIN_' + displayMsg.type.toString() + '_FAIL' }));

        } else if (submitPin.callData) {
            const resultData = getResultData();
            setResetVerificationRequest(true);
            if (resultData.status !== 'SUCCESS') setErrorNotification(intl.formatMessage({ id: resultData.status + '_PIN' }));
            else {
                setGeneralCookie(`c-cooldown-start-${VerificationType.PIN}`, '59', 59);
                setSuccessNotification(intl.formatMessage({ id: 'PIN_' + displayMsg.type.toString() + '_SUCCESS' }));
                modalVisible.setVisible(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitPin.callData]);

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                const variables = submitForm(displayMsg.type, values);
                submitPin.callFunction({ variables });
                form.resetFields();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });

    };

    const requestStatus = () => {
        requestPin({
            variables: {
                verificationType: VerificationType.PIN.toString()
            }
        });
    }

    const getResultData = () => {
        if(displayMsg.type === 'CREATE') {
            return submitPin.callData.userPinCreate;
        } else if(displayMsg.type === 'UPDATE') {
            return submitPin.callData.userUpdatePin;
        }
        return submitPin.callData.userPinAffirm;
    }

    const submitForm = (type: string, values: any) => {
        switch (type) {
            case 'CREATE':
            case 'AFFIRM':
                return {
                    pinCode: Buffer.from(values.pin).toString('base64')
                };
            case 'UPDATE':
                return {
                    pinCode: Buffer.from(values.pin).toString('base64'),
                    verificationCode: values.otp
                };
            default:
                return null;
        }
    }

    const checkDuplicatePin = (value: string) => {
        const valueArr = value.split('');
        for (var i = 0; i < valueArr.length; i++) {
            if ((valueArr[i] !== valueArr[0]) && !(['123456', '654321'].includes(value))) {
                return false;
            }
        }
        return true;
    }

    const innerLayout = (type: string) => {
        switch (type) {
            case 'CREATE':
                return (<>

                    <PinCodeInput limitWidth={true} name="pin" length={6} label={intl.formatMessage({ id: "PIN_SET" })}
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'PIN_SET_REQUIRE' }) },
                            { pattern: new RegExp(/^[\d]+$/), message: intl.formatMessage({ id: 'INVALID_PIN' }) },
                            { min: 6, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                            () => ({
                                validator(_, value) {
                                    if(checkDuplicatePin(value) && value.length === 6) {
                                        return Promise.reject(new Error(intl.formatMessage({ id: 'FORMAT_INVALID_PIN' })));
                                    }
                                    return Promise.resolve();
                                }
                            })
                        ]}
                    />
                    <PinCodeInput limitWidth={true} name="confirmPin" length={6} label={intl.formatMessage({ id: "PIN_CONFIRM" })}
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'PIN_CONFIRM_REQUIRE' }) },
                            { pattern: new RegExp(/^[\d]+$/), message: intl.formatMessage({ id: 'INVALID_PIN' }) },
                            { min: 6, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('pin') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(intl.formatMessage({ id: 'PIN_CONFIRM_NOT_MATCH' })));
                                },
                            })
                        ]}
                    />
                </>);
            case 'AFFIRM':
                return (<>
                    <PinCodeInput name="pin" length={6} limitWidth={true} />
                </>);
            case 'UPDATE':
                return (<>
                    <PinCodeInput limitWidth={true} name="pin" length={6} label={intl.formatMessage({ id: "PIN_SET" })}
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'PIN_SET_REQUIRE' }) },
                            { pattern: new RegExp(/^[\d]+$/), message: intl.formatMessage({ id: 'INVALID_PIN' }) },
                            { min: 6, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if(checkDuplicatePin(value) && value.length === 6) {
                                        setIsPinMatched(false);
                                        return Promise.reject(new Error(intl.formatMessage({ id: 'FORMAT_INVALID_PIN' })));
                                    } else if (getFieldValue('confirmPin') !== value) {
                                        setIsPinMatched(false);
                                        return Promise.resolve();
                                    } else if (value.length === 6) {
                                        setIsPinMatched(true);
                                        return Promise.resolve();
                                    }
                                }
                            })
                        ]}
                    />
                    <PinCodeInput limitWidth={true} name="confirmPin" length={6} label={intl.formatMessage({ id: "PIN_CONFIRM" })}
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'PIN_CONFIRM_REQUIRE' }) },
                            { pattern: new RegExp(/^[\d]+$/), message: intl.formatMessage({ id: 'INVALID_PIN' }) },
                            { min: 6, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if(checkDuplicatePin(value) && value.length === 6) {
                                        setIsPinMatched(false);
                                        return Promise.resolve();
                                    } else if (getFieldValue('pin') !== value) {
                                        setIsPinMatched(false);
                                        return Promise.reject(new Error(intl.formatMessage({ id: 'PIN_CONFIRM_NOT_MATCH' })));
                                    } else if (!value || getFieldValue('pin') === value) {
                                        setIsPinMatched(true);
                                        return Promise.resolve();
                                    }
                                },
                            })
                        ]}
                    />
                    <div className="sms-same-row-container otp-container">
                        <Form.Item name="otp" required
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'CODE_REQUIRE' }) },
                            ]}>
                            <Input maxLength={6} placeholder={intl.formatMessage({ id: 'EMAIL_VERIFICATION_INPUT_PLACEHOLDER' })} />
                        </Form.Item>
                        <CodeRequestButton type={VerificationType.PIN} hasError={requestPinError ? true : false} isSuccess={resetVerificationRequest} requestStatus={requestStatus} isPinMatched={isPinMatched} />
                    </div>
                </>);
            default:
                return null;
        }
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 48 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 48 },
            sm: { span: 40 },
            md: { span: 40 },
            lg: { span: 40 },
        },
    };

    const modalFooter = (_canClose: boolean) => {
        if (!_canClose) return [<Button key="submit" type="primary" onClick={handleOk}>{displayMsg.submitBtnTitle}</Button>];
        else return [<Button key="back" onClick={handleCancel}>{intl.formatMessage({ id: "CANCEL" })}</Button>,
        <Button key="submit" type="primary" onClick={handleOk}>{displayMsg.submitBtnTitle}</Button>];
    }

    return (<>
        <Modal destroyOnClose={true} title={displayMsg.title} closable={canClose} visible={modalVisible.visible} onOk={handleOk} onCancel={handleCancel}
            footer={modalFooter(canClose)}
            okText={displayMsg.submitBtnTitle} cancelText={intl.formatMessage({ id: "CANCEL" })}>
            <p> {displayMsg.attachedText}</p>
            <Form form={form} {...formItemLayout}>
                {innerLayout(displayMsg.type)}
            </Form>
        </Modal>
    </>)
}
export default MyProfilePinDialog;