import TradingConsultants from "./TradingConsultants";
import Profile from "./dashboard-components/Profile";
import NewsUpdates from "./dashboard-components/NewsUpdates";
import AlertComponent from './dashboard-components/Alerts';
import { UPDATE_CURRENT_USER_PROFILE, SET_CURRENT_USER, useAuth, UPDATE_CHANGE_PASSWORD_STATUS } from "../../hooks/auth/AuthContext";
import { useIntl } from "react-intl";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import useSpinner from "../../hooks/layout/useSpinner";
import MyProfilePinDialog from "../account/my-profile/MyProfilePinDialog";
import useNotification from "../../hooks/layout/useNotification";
import { GET_USER_PROFILE, CREATE_PIN } from "../../shared/gql/profile.gql";
import { GET_ANNOUNCEMENT, GET_AVAILABLE_TNC_BY_TYPE, GET_BANNER, GET_EXPERT_ADVISORS, GET_PROFILE, GET_UNREAD_POPUP_ANNOUNCEMENT, INSERT_AGREED_TNC, REGISTER_SUCCESS_VERIFY_EMAIL, SET_PASSWORD } from './Home.gql';
import "./Home.less";
import MarqueeComponent from "./dashboard-components/Marquee";
import { setTokenCookie } from "../../shared/helpers/set-token-cookies.helper";
import AnnouncementPopUpDialog from "./dashboard-components/dialogs/AnnouncementPopUpDialog";
import { setDBLanguageToGet } from "../../shared/helpers/language.helpers";
import { REDIRECT_TO_APP, useLayout } from "../../hooks/layout/LayoutContext";
import { useCookies } from "react-cookie";
import SetPasswordDialog from "./dashboard-components/dialogs/SetPasswordDialog";
import CorporateFundTNCDialog from "./dashboard-components/dialogs/CorporateFundTNCDialog";
import { getEligibleRanks } from "../../shared/helpers/mask-label.helpers";
import VerifyEmailDialog from "./dashboard-components/dialogs/VerifiyEmailDialog";
import PointAgreeDialog from "./dashboard-components/dialogs/PointAgreeDialog";
import RedirectAppPopUpDialog from "./dashboard-components/dialogs/RedirectAppPopUpDialog";
import ApiHelper from "../../shared/helpers/api.helper";
import apiConstant from "../../shared/helpers/apiConstant";

const Home = () => {
    const { layoutState, layoutDispatch } = useLayout();
    const { authState, authDispatch } = useAuth();
    const { setLoading } = useSpinner();
    const intl = useIntl();
    const [isPinSetup, setIsPinSetup] = useState<boolean>(false);
    const [requiredPasswordChange, setrequiredPasswordChange] = useState<boolean>(false);
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [getProfile, { data: getData }] = useLazyQuery(GET_USER_PROFILE, { fetchPolicy: "no-cache" });
    const [createPin, { data: createPinData, error: createPinError, loading: createPinLoading }] = useMutation(
        CREATE_PIN,
        {
            refetchQueries: [{ query: GET_USER_PROFILE, variables: { fetchPolicy: "no-cache" } }],
            errorPolicy: "all",
        }
    );
    const [setPassword, { data: setPasswordData, error: setPasswordError, loading: setPasswordLoading }] = useMutation(SET_PASSWORD);
    const { data: bannerData } = useQuery(GET_BANNER, { fetchPolicy: 'cache-and-network' });
    const { data: marqueeData } = useQuery(GET_ANNOUNCEMENT, { fetchPolicy: "no-cache" });
    const { data: profileData, loading: profileLoading } = useQuery(GET_PROFILE, { fetchPolicy: 'cache-and-network' });
    const { data: consultantData } = useQuery(GET_EXPERT_ADVISORS, { fetchPolicy: 'cache-and-network' });
    const { data: unreadAnnouncementData } = useQuery(GET_UNREAD_POPUP_ANNOUNCEMENT, { fetchPolicy: "no-cache" });
    const [hasUnseenAnnouncement, setHasUnseenAnnouncement] = useState<boolean>(false);
    const [submitOTPCode, { data: submitOTPData, error: submitOTPCodeError }] = useMutation(REGISTER_SUCCESS_VERIFY_EMAIL, { errorPolicy: 'all' });
    const [announcementSeen, setAnnouncementSeen] = useState<string>('false');
    const [cookies] = useCookies();
    const [isCorporateFundTNCVisible, setIsCorporateFundTNCVisible] = useState<boolean>(false);
    const { data: tncByTypeData } = useQuery(GET_AVAILABLE_TNC_BY_TYPE, { variables: { tncType: 'CORPORATE_FUND' }, fetchPolicy: "no-cache" });
    const { data: thirdPartyTNCData } = useQuery(GET_AVAILABLE_TNC_BY_TYPE, { variables: { tncType: 'THIRD_PARTY' }, fetchPolicy: "no-cache" });
    const [hasUnreadCorporateFundTNC, setHasUnreadCorporateFundTNC] = useState<boolean>(false);
    const [hasUnreadThirdPartyTNC, setHasUnreadThirdPartyTNC] = useState<boolean>(false);
    const [insertAgreedTNC, { data: insertAgreedTNCData, error: insertAgreedTNCError }] = useMutation(INSERT_AGREED_TNC, { errorPolicy: "all", });
    const [showPointAgreeDialog, setShowPointAgreeDialog] = useState<any>(false);
    const [showRedirectAppDialog, setShowRedirectAppDialog] = useState<any>(true);

    useEffect(() => {
        getProfile();
        let hasAnnouncementSeen = cookies[`c-announcement-read`];
        setAnnouncementSeen(hasAnnouncementSeen ?? 'false');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (createPinLoading || setPasswordLoading || profileLoading) setLoading(true);
        else setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createPinLoading || setPasswordLoading || profileLoading]);

    useEffect(() => {
        if (authState.requiredPasswordChange) {
            setrequiredPasswordChange(authState.requiredPasswordChange);
        }
    }, [authState.requiredPasswordChange])

    useEffect(() => {
        setShowRedirectAppDialog(layoutState.redirectAppDialog.isRedirect)
        console.log(layoutState.redirectAppDialog.isRedirect)
    }, [layoutState.redirectAppDialog.isRedirect])

    const isRequiredPasswordChange = () => {
        return authState.requiredPasswordChange && authState.requiredPasswordChange.toString() === 'true';
    }

    useEffect(() => {
        if (getData) {
            const _profile = getData && getData.userProfile ? getData.userProfile : null;
            if (_profile) {
                if (!!authState) authDispatch({ type: SET_CURRENT_USER, payload: getData });
                else authDispatch({ type: UPDATE_CURRENT_USER_PROFILE, payload: getData });
                if (!_profile?.pinStatus) setIsPinSetup(true);
                setUnreadAnnouncementData();
                setUnreadThirdPartyTNC();
                getIsLinkPayment(getData.userProfile?.strMemberId)

                setShowPointAgreeDialog(getData.userProfile?.pointAccepted === false)
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getData, unreadAnnouncementData, thirdPartyTNCData])

    useEffect(() => {
        if (createPinError) {
            setErrorNotification(intl.formatMessage({ id: "PIN_CREATE_FAIL" }));
        } else if (createPinData) {
            if (createPinData.userPinCreate.status !== "SUCCESS") {
                if (createPinData.userPinCreate.status === "FORMAT_INVALID") {
                    setErrorNotification(intl.formatMessage({ id: `${createPinData.userPinCreate.status}_PIN` }));
                } else {
                    setErrorNotification(intl.formatMessage({ id: createPinData.userPinCreate.status }));
                }
            } else {
                setSuccessNotification(intl.formatMessage({ id: "PIN_CREATE_SUCCESS" }));
                setIsPinSetup(false);
                getProfile();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createPinData]);

    useEffect(() => {
        if (setPasswordError) {
            setErrorNotification(intl.formatMessage({ id: "SET_PASSWORD_FAIL" }));
        } else if (setPasswordData) {
            setSuccessNotification(intl.formatMessage({ id: "SET_PASSWORD_SUCCESS" }));
            setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || 'scf-at', setPasswordData.setPassword?.a);
            authDispatch({ type: UPDATE_CHANGE_PASSWORD_STATUS, payload: false });
            setrequiredPasswordChange(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPasswordData, setTokenCookie]);

    const handlePinClick = () => {
        setIsPinSetup(true);
    };

    useEffect(() => {
        if (tncByTypeData && tncByTypeData?.tncByType) {
            setHasUnreadCorporateFundTNC(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tncByTypeData]);

    const handleCorporateFundClick = () => {
        console.log('click');
        setIsCorporateFundTNCVisible(true);
    };

    useEffect(() => {
        if (createPinError) {
            setErrorNotification(intl.formatMessage({ id: "CORPORATE_FUND_TNC_FAIL" }));
        } else if (insertAgreedTNCData) {
            if (insertAgreedTNCData.insertAgreedTNC.status !== "SUCCESS")
                setErrorNotification(intl.formatMessage({ id: createPinData.userPinCreate.status }));
            else {
                setSuccessNotification(intl.formatMessage({ id: "CORPORATE_FUND_TNC_SUCCESS" }));
                setHasUnreadCorporateFundTNC(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insertAgreedTNCData]);

    const setUnreadAnnouncementData = () => {
        if (announcementSeen === 'false' && unreadAnnouncementData && unreadAnnouncementData?.popUpUnreadAnnouncement) {
            setHasUnseenAnnouncement(true);
        }
    }

    const setUnreadThirdPartyTNC = () => {
        if (thirdPartyTNCData && thirdPartyTNCData?.tncByType) {
            setHasUnreadThirdPartyTNC(true);
        }
    }

    const getIsLinkPayment = (memberId: string) => {
        if (memberId && memberId !== null) {
            if (memberId.trim() !== '') {
                let payload = {
                    "memberId": memberId
                }

                ApiHelper.postMethod(apiConstant.url.linkMember, payload,
                    (data: any) => {
                        var isLinked = false;
                        if (data.isLinked && data.isLinked !== null) {
                            isLinked = data.isLinked
                            let payload = layoutState.redirectAppDialog
                            payload.isLinkedPayment = isLinked
                            layoutDispatch({ type: REDIRECT_TO_APP, payload: payload  });
                        }
                    },
                    (error: any) => {
                        console.log('getIsLinkPayment error')
                        console.log(error)
                    }
                )
            }
        }
    }

    return (
        <div className="home-container">
            {marqueeData?.announcement ? <MarqueeComponent marqueeData={marqueeData} /> : null}
            <AlertComponent handlePinClick={handlePinClick} handleCorporateFundClick={handleCorporateFundClick} hasUnreadCorporateFundTNC={hasUnreadCorporateFundTNC} />
            <Profile profileData={profileData} />
            <NewsUpdates bannerData={bannerData} />
            <TradingConsultants consultantData={consultantData} />
            {/* <div className="combine-panel">
                <Competition competitionData={competitionData} openCompetitionData={openCompetitionData} />
                <Referral />
            </div> */}
            {showPointAgreeDialog === true &&
                <PointAgreeDialog
                    modalVisible={{ visible: showPointAgreeDialog, setVisible: setShowPointAgreeDialog }} />
            }
            {showRedirectAppDialog === true &&
                <RedirectAppPopUpDialog
                    modalVisible={{ visible: showRedirectAppDialog, setVisible: setShowRedirectAppDialog }}
                        canClose={true}
                    />
            }
            {isRequiredPasswordChange() ? (
                <SetPasswordDialog
                    modalVisible={{ visible: (showPointAgreeDialog && requiredPasswordChange), setVisible: setrequiredPasswordChange }}
                    submitPassword={{ callFunction: setPassword, callError: setPasswordError }}
                />
            ) : null}
            <MyProfilePinDialog
                modalVisible={{ visible: (isPinSetup && !requiredPasswordChange), setVisible: setIsPinSetup }}
                canClose={false}
                submitPin={{ callFunction: createPin, callData: createPinData, callError: createPinError }}
                displayMsg={{
                    title: intl.formatMessage({ id: "PIN_CREATE" }),
                    type: "CREATE",
                    submitBtnTitle: intl.formatMessage({ id: "PIN_CREATE_SUBMIT" }),
                    attachedText: intl.formatMessage({ id: "PIN_CREATE_ATTACHED" }),
                }}
            />
            <AnnouncementPopUpDialog
                modalVisible={{ visible: hasUnseenAnnouncement && !isPinSetup, setVisible: setHasUnseenAnnouncement }}
                canClose={false}
                displayMsg={{
                    title: intl.formatMessage({ id: "GENERAL_ANNOUNCEMENT" }),
                    submitBtnTitle: intl.formatMessage({ id: "CLOSE" }),
                    attachedText: unreadAnnouncementData?.popUpUnreadAnnouncement?.message[setDBLanguageToGet(layoutState.locale)],
                    id: unreadAnnouncementData?.popUpUnreadAnnouncement?.id
                }}
            />
            <VerifyEmailDialog
                modalVisible={{ visible: hasUnreadThirdPartyTNC && !hasUnseenAnnouncement && !isPinSetup, setVisible: setHasUnreadThirdPartyTNC }}
                title={authState.userProfile?.emailVerified ? thirdPartyTNCData && thirdPartyTNCData?.tncByType?.tnc?.title[setDBLanguageToGet(layoutState.locale)] : intl.formatMessage({ id: 'VERIFY_EMAIL' })}
                submitOTP={{ callFunction: submitOTPCode, callData: submitOTPData, callError: submitOTPCodeError }}
                tncDocument={thirdPartyTNCData && thirdPartyTNCData?.tncByType?.tNCDocument ? thirdPartyTNCData?.tncByType?.tNCDocument : null}
                insertAgreedTNC={{ callFunction: insertAgreedTNC, callError: insertAgreedTNCError }}
                displayMsg={{
                    title: thirdPartyTNCData && thirdPartyTNCData?.tncByType?.tnc?.title[setDBLanguageToGet(layoutState.locale)],
                    submitBtnTitle: intl.formatMessage({ id: "SUBMIT" }),
                    attachedText: thirdPartyTNCData && thirdPartyTNCData?.tncByType?.tnc?.content[setDBLanguageToGet(layoutState.locale)],
                    id: thirdPartyTNCData && thirdPartyTNCData?.tncByType?.tnc?.id,
                }} />
            {(getEligibleRanks(authState.userProfile?.userRank ?? '', 'CORPORATE_FUND') && hasUnreadCorporateFundTNC === true) &&
                <CorporateFundTNCDialog
                    modalVisible={{ visible: isCorporateFundTNCVisible, setVisible: setIsCorporateFundTNCVisible }}
                    canClose={false}
                    displayMsg={{
                        title: tncByTypeData && tncByTypeData?.tncByType?.tnc?.title[setDBLanguageToGet(layoutState.locale)],
                        submitBtnTitle: intl.formatMessage({ id: "SUBMIT" }),
                        attachedText: tncByTypeData && tncByTypeData?.tncByType?.tnc?.content[setDBLanguageToGet(layoutState.locale)],
                        id: tncByTypeData && tncByTypeData?.tncByType?.tnc?.id,
                    }}
                    tncDocument={tncByTypeData && tncByTypeData?.tncByType?.tNCDocument ? tncByTypeData?.tncByType?.tNCDocument : null}
                    insertAgreedTNC={{ callFunction: insertAgreedTNC, callError: insertAgreedTNCError }}
                />
            }
        </div>
    );
};

export default Home;
