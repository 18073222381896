import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import { Avatar, Divider, Button, Menu, Dropdown, Typography, Modal } from "antd";
import { MoreOutlined } from '@ant-design/icons';
import "./TradingConsultants.less";
import { FormattedMessage } from "react-intl";
import { useAuth } from "../../hooks/auth/AuthContext";
import { matchEAWithMasterType } from './TradingConsultant.helper';
import { useLayout } from '../../hooks/layout/LayoutContext';
const { Title } = Typography;
//TODO: 
// 1. Add in exact Link path

const TradingConsultants = ({ consultantData }: any) => {
    const { authState } = useAuth();
    const [expertAdvisors, setExpertAdvisors] = useState([]);
    const [introVideo, setIntroVideo] = useState<string>();
    const [eaName, setEAName] = useState<string>();
    const { layoutState } = useLayout();

    useEffect(() => {
        if (consultantData) {
            const _expertAdvisors = consultantData.expertAdvisors && consultantData.expertAdvisors.length > 0 ?
                consultantData.expertAdvisors.map((val: any) => {
                    return {
                        ...val,
                        key: val.id,
                        tradeRecord: val?.fxBookUrl ? val?.fxBookUrl: getTradeRecord(val.id),
                    };
                }) : [];
            setExpertAdvisors(_expertAdvisors);
        }
    }, [consultantData]);

    const showVideo = (id: string) => {
        switch (id) {
            case 'ckp7vbheb000001l0dfij5kj7':
                setIntroVideo('https://www.youtube.com/embed/3B3DMIj9A3c');
                setEAName('Olivia Watson');
                break;
            case 'ckp7vbqre000101l0dx5ybc11':
                setIntroVideo('https://www.youtube.com/embed/9O3iRbgiTjQ');
                setEAName('Alex Rude')
                break;
            case 'ckp7vbsky000201l0e9bm10si':
                setIntroVideo('https://www.youtube.com/embed/BtRh6amOLkg');
                setEAName('Gevin Wallace')
                break;
            default:
                break;
        }
    }

    const menu = (id: string) => (
        <Menu>
            <Menu.Item key="0" onClick={() => showVideo(id)}>
                <FormattedMessage id="INTRODUCTION" />
            </Menu.Item>
            {/* <Menu.Item key="1">
                <Link to={`/member/trading-consultants/testimonials/${id}`}><FormattedMessage id="TESTIMONIALS" /></Link>
            </Menu.Item> */}
        </Menu>
    );

    const getTradeRecord = (val: string) => {
        if (val === 'ckp7vbsky000201l0e9bm10si') return 'https://www.myfxbook.com/portfolio/foreign-exchange-master-gevin-wallace/9898710';
        if (val === 'ckp7vbqre000101l0dx5ybc11') return 'https://www.myfxbook.com/portfolio/indices-master-alex-rude/9898726';
        if (val === 'ckp7vbheb000001l0dfij5kj7') return 'https://www.myfxbook.com/members/primafxint/gold-master-olivia-watson/6670249';
        if (val === 'ckxfo6w0q00041bn62dsw0pe0') return 'https://www.myfxbook.com/portfolio/indices-master-victor-titov/9898730';
        if (val === 'ckxfo8qzd00051bn6dwc15wop') return 'https://www.myfxbook.com/portfolio/commodities-master-alison-vodianova/9898751';
        if (val === 'ckxfo9hpq00061bn6dhkqhttd') return 'https://www.myfxbook.com/portfolio/foreign-exchange-master-elina-salazova/9898747';
        if (val === 'cl8cjzma300040ajsaxlg82cw') return 'https://www.myfxbook.com/members/Herzen/pamm-h-fund-和鑫基金/9900417';
    }

    return (
        <div className="trading-consultant-container">
            <div className="trading-consultant-header">
                <Title level={4}><FormattedMessage id="TRADING_CONSULTANTS" /></Title>
                <span className="sub-title"><FormattedMessage id="CHOOSE_OUR_BEST_EA" /></span>
            </div>
            <div className="trading-consultant-items">
                {
                    expertAdvisors.map((val: any) => {
                        return (<div key={val.id} className="advisor-details-form">
                            <div className="advisor-details">
                                <div><Avatar className="advisor-avatar" src={val.photoUrl} size={150} /></div>
                                <div className={layoutState.locale === 'th' ? 'advisor-detail-container-long' : 'advisor-detail-container'}>
                                    <div className="advisor-name-container">
                                        <div className="advisor-name">{val.name}</div>
                                        <Dropdown
                                            overlay={menu(val.id)}>
                                            <Button type="link" size="small">
                                                <MoreOutlined className="icon" />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                    <div className={layoutState.locale === 'th' ? 'advisor-master-field-long' : 'advisor-master-field'}>
                                        {
                                            <FormattedMessage id={matchEAWithMasterType(val.id)} />
                                        }
                                    </div>
                                    <div className={layoutState.locale === 'th' ? "advisor-performance-month-long" : "advisor-performance-month"}>
                                        <FormattedMessage id={val.performanceMonth ? val.performanceMonth.toUpperCase() : val.lastMonth ? val.lastMonth.toUpperCase() : '-'} />
                                    </div>
                                    <div className="trading-details">
                                        <div className={layoutState.locale === 'th' ? "advisor-roi-percent-long" : "advisor-roi-percent"}>
                                            <span><FormattedMessage id="ROI_PERCENT" /></span>
                                            <span>{val.roi}%</span>
                                        </div>
                                        <div className={layoutState.locale === 'th' ? "advisor-total-lot-long" : "advisor-total-lot"}>
                                            <span><FormattedMessage id="TOTAL_LOT" /></span>
                                            <span>{val.lotSize}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider className="divider-color" />
                            <div className="trading-buttons">
                                <Button type="link" block>
                                    <a target="_blank" rel="noopener noreferrer" href={val.tradeRecord}>
                                        <FormattedMessage id="TRADE_RECORDS" />
                                    </a>
                                </Button>
                                {
                                    val.id !== 'cl8cjzma300040ajsaxlg82cw' &&
                                    <>
                                        <Divider type="vertical" className="button-divider" />
                                        {val.id !== authState?.userProfile?.mt4Wallet[val.id]?.eaId ?
                                            <Button type="link" block>
                                                <Link to={`/member/copy-trade/${val.id}`}>
                                                    <FormattedMessage id="COPY_TRADE" />
                                                </Link>
                                            </Button> :
                                            <Button disabled={true} type="link" block className="followed-button">
                                                {val.id !== 'cl8cjzma300040ajsaxlg82cw' ? <FormattedMessage id="FOLLOWED" /> : <FormattedMessage id="SELECTED" />}
                                            </Button>
                                        }
                                    </>
                                }
                            </div>
                        </div>)
                    })
                }
            </div>
            <Modal width={(window.innerWidth / 2) + 44} title={eaName} visible={!!introVideo}
                destroyOnClose
                onCancel={() => setIntroVideo(undefined)} footer={null}>
                <iframe width={window.innerWidth / 2} height={(window.innerWidth / 2) / 560 * 315} src={introVideo} title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                </iframe>
            </Modal>
        </div>
    )
}

export default TradingConsultants;