import { useQuery } from "@apollo/client";
import { Button, Layout, Typography } from "antd"
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import logo from './assets/images/herzen/logo.svg'
import background from './assets/images/celebration/background.jpg';
import { GET_THEME } from "./pages/auth/auth.gql";
const { Content } = Layout;
const { Title } = Typography;

const NoMatch = () => {
    const [backgroundImg, setBackgroundImg] = useState<any>({});
    const { data: themeData } = useQuery(GET_THEME, { fetchPolicy: 'cache-and-network' });

    useEffect(() => {
        if (themeData)
            setBackgroundImg({ background: `url(${themeData.theme?.mainBgUrl ?? background}) 50% center / cover` });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeData]);

    return (
        <Layout>
            <Content className="anonymous-container" style={{ ...backgroundImg }}>
                <div className="anonymous-wrapper">
                    <div className="logo-container">
                        <img src={logo} alt="herzen-logo" />
                    </div>
                    <div className="footer-container">
                        <Title level={3}><FormattedMessage id="NOT_FOUND_TITLE" /></Title>
                        <Title level={4}><FormattedMessage id="NOT_FOUND_CONTENT" /></Title>
                        <br />
                        <Link to="/member">
                            <Button type="primary"><FormattedMessage id="GENERAL_HOME" /></Button>
                        </Link>
                    </div>

                    <div className="footer-container font-caption">
                        <FormattedMessage id="COPY_RIGHT" values={{
                            year: new Date().getFullYear().toString()
                        }} />
                    </div>
                </div>
            </Content>
        </Layout>
    );
}

export default NoMatch;