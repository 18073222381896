import { ApolloError } from "@apollo/client";
import { Button, Form, Input, Modal } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./Dialogs.less";
import NumberFormat from "react-number-format";
import VideoViewer from "../../../../shared/widgets/video-viewer/video-viewer";

import {
    CopyOutlined
} from "@ant-design/icons";


interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
    submit: { callFunction: any, callError: ApolloError | undefined };
    point: { rPoint: number, wPoint: number };
    isCancel: { cancel: boolean, setCancel: Dispatch<SetStateAction<boolean>> };
    cryptoWalletObject: any
}

const CryptoWalletDialog = ({ modalVisible, isCancel, submit, point, cryptoWalletObject }: Props) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isAgree, setIsAgree] = useState<boolean>(false);
    const uniqueToken = '0x9ed2DD8F706b24d55421125181564f528fdDCdda'

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                const cryptoWalletAddInput = { 
                    walletAddress: values.walletAddress, 
                    wPoint: point.wPoint, 
                    rPoint: point.rPoint, 
                    status: 'PENDING'
                }
                submit.callFunction({ variables: { cryptoWalletAddInput } });
                // modalVisible.setVisible(false);
                isCancel.setCancel(false);
                // form.resetFields();

                setIsAgree(true)
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };


    const handleClose = () => {
        let isExist = false
        if(cryptoWalletObject && cryptoWalletObject !== null) {
            if (cryptoWalletObject.status && cryptoWalletObject.status !== null) {
                isExist = true
            }
        }
        
        if(!isExist) {
            const cryptoWalletAddInput = {
                walletAddress: '',
                rPoint: 0,
                wPoint: 0,
                status: 'CANCELLED'
            }
            submit.callFunction({ variables: { cryptoWalletAddInput } });
            form.resetFields();
        }
        modalVisible.setVisible(false);
        isCancel.setCancel(true);
    }

    const handleAgreeAndClose = () => {
        modalVisible.setVisible(false);
        isCancel.setCancel(false);
        form.resetFields();
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 48 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 48 },
            sm: { span: 40 },
            md: { span: 40 },
            lg: { span: 40 },
        },
    };

    const walletStyle = {
        paddingBottom: 10,
    }


    const showVideo = () => {
        setIsModalVisible(true);
    }

    const footerList = () => {
        if(isAgree) {
            return [
                <Button key="Close" onClick={handleAgreeAndClose}><FormattedMessage id="CLOSE" /></Button>
            ]
        }

        return [
            <Button key="submit" type="primary" onClick={handleOk}>{intl.formatMessage({ id: "AGREE" })}</Button>,
            <Button key="Close" onClick={handleClose}><FormattedMessage id="CLOSE" /></Button>
        ]
    }

    const handleCopyUrl = () => {
        navigator.clipboard.writeText(uniqueToken);
    }

    return (
        <Modal title={<FormattedMessage id="CRYPTO_WALLET" />} visible={true} onOk={handleOk} width={600} closable={false}
            footer={footerList()}
            cancelText={intl.formatMessage({ id: "CANCEL" })}>
            {point && point.rPoint >= 0
                &&
                <div style={{ ...walletStyle }}>
                    <FormattedMessage id="RPOINT" /> - <NumberFormat value={point.rPoint} displayType={'text'} thousandSeparator={true} />
                </div>
            }
            {point && point.wPoint >=0
                &&
                <div style={{ ...walletStyle }}>
                    <FormattedMessage id="WPOINT" /> - <NumberFormat value={point.wPoint} displayType={'text'} thousandSeparator={true} />
                </div>
            }
            <Form form={form} {...formItemLayout}>
                <Form.Item name="walletAddress" label={<><FormattedMessage id="UNIQUE_TOKEN_WALLET_ADDRESS" /></>} className="password-dialog-input" required
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'NEW_CRYPTO_WALLET_ADDRESS_REQUIRED' }) },
                        { min: 42, message: intl.formatMessage({ id: 'UNIQUE_TOKEN_WALLET_ADDRESS' }) },
                        // {
                        //     pattern: /^T[0-9a-zA-Z]{33}$/,
                        //     message: intl.formatMessage({ id: 'CRYPTO_WALLET_ADDRESS_REQUIRED_FORMAT' })
                        // }
                    ]}>
                    <Input
                        maxLength={42}
                        placeholder={intl.formatMessage({ id: "NEW_CRYPTO_WALLET_ADDRESS_REQUIRED" })}
                    />
                </Form.Item>
            </Form>
            <div style={{ ...walletStyle }}>
                <FormattedMessage id="CRYPTO_WALLET_AGREE" />
            </div>
            <div>
                <Button className="user-guide" onClick={() => showVideo()} size="large">
                    <FormattedMessage id={"VIDEO_GUIDE"} />
                </Button>
            </div>
            {
                isAgree &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <div style={{ ...walletStyle, paddingTop: 10 }}>
                        <FormattedMessage id="DXA_CHAIN_CONTRACT_ADDRESS" />
                        : {uniqueToken}
                    </div>
                    
                    <Button onClick={handleCopyUrl}>
                            <CopyOutlined />
                    </Button>
                </div>
            }
            
            <VideoViewer
                videoNames={["USER_GUIDE_VIDEO_USE_UNIQUE_TOKEN"]}
                videoTitle={"USER_GUIDE_USE_UNIQUE_TOKEN"}
                videoBlobContainer="user-guides"
                modalVisible={{ visible: isModalVisible, setVisible: setIsModalVisible }} />
        </Modal>
    )
}
export default CryptoWalletDialog;