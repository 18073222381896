import { useQuery } from "@apollo/client";
import { Typography, Collapse, Carousel, Button, Divider, Empty } from "antd";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { GET_TOP_NEWS } from '../Home.gql';
import './NewsUpdates.less';
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { setDBLanguageToGet } from "../../../shared/helpers/language.helpers";
const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const NewsUpdates = ({ bannerData }: any) => {
    const history = useHistory();
    const { data: topnewsData } = useQuery(GET_TOP_NEWS, { fetchPolicy: 'cache-and-network' });
	const { layoutState } = useLayout();

    const handleNewsClick = (id: string) => {
        history.push(`/member/news/${id}`);
    }

    return (
        <div className="news-updates-container">
            <Collapse expandIconPosition="right" bordered={false} defaultActiveKey={1}>
                <Panel key="1" header={<Title level={4}><FormattedMessage id="NEWS_PROMOTIONS" /></Title>}>
                    {bannerData?.banners?.length === 0 && topnewsData?.topNews?.length === 0 ?
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
                        <div className="news-container">
                            <div className="divCenter">
                                {bannerData?.banners?.length > 0 ?
                                    <Carousel autoplay className="carousell-container">
                                        {bannerData && bannerData.banners?.map((banner: any) => {
                                            return (
                                                <div key={banner.id}>
                                                    <img alt={banner.title} className="carousell-image" src={banner.pictureUrl} />
                                                </div>
                                            );
                                        })}
                                    </Carousel>
                                : 
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </div>
                            <Divider type="vertical" />
                            <div className="divCenter">
                                {topnewsData && topnewsData.topNews.map((news: any) => {
                                    return (
                                        <div key={news.id} className="topnews-container" onClick={() => handleNewsClick(news.id)}>
                                            <img alt={news.title[setDBLanguageToGet(layoutState.locale)]} src={news.pictureUrl} />
                                            <div className="topnews-details">
                                                <Paragraph className="news-title" ellipsis={{ rows: 2 }}>{news.title[setDBLanguageToGet(layoutState.locale)]}</Paragraph>
                                                <span className="grayed-text">{moment(news.createdAt).format('YYYY-MM-DD')}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                                {topnewsData?.topNews?.length > 0 ? 
                                    <Button type="link">
                                        <Link to="/member/news">
                                            <FormattedMessage id="READ_ALL_NEWS" />
                                        </Link>
                                    </Button>
                                : 
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }
                            </div>
                        </div>
                    }
                </Panel>
            </Collapse>
        </div>
    )
}

export default NewsUpdates;
