import { Suspense, useEffect, useState } from 'react';
import { Switch, Route, BrowserRouter as Router, useHistory, useLocation } from 'react-router-dom';
import GeneralFooter from "./shared/components/footer/GeneralFooter";
import GeneralHeader from "./shared/components/header/GeneralHeader";
import SideMenu from './shared/components/side-menu/SideMenu';
import Home from './pages/home/Home';
import { Layout, Spin, notification, Modal, Button } from 'antd';
import { CLEAR_CURRENT_USER, SET_CURRENT_USER, SET_LOGIN_STATUS, useAuth } from './hooks/auth/AuthContext';
import { CLEAR_NOTIFICATION, useLayout } from './hooks/layout/LayoutContext';
import { routes, RouteInfo } from './routes';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_USER_PROFILE } from './shared/gql/profile.gql';
import { useIntl } from 'react-intl';
import Cookies from 'universal-cookie';
import { clearAllTokens, removeMainAccountPopUp } from './shared/helpers/set-token-cookies.helper';
import { client } from './apollo-clients';
import { io } from "socket.io-client";
import { includes } from 'lodash';
import { useCookies } from 'react-cookie';
import ApiHelper from './shared/helpers/api.helper';
import apiConstant from './shared/helpers/apiConstant';
import CryptoWalletDialog from './pages/home/dashboard-components/dialogs/CryptoWalletDialog';
import { ADD_CRYPTO_WALLET, GET_CRYPTO_WALLET } from './pages/home/Home.gql';
import useNotification from './hooks/layout/useNotification';
import VideoViewer from './shared/widgets/video-viewer/video-viewer';

const cookiesGet = new Cookies();
const { Content } = Layout;

const AppProtected = () => {
    const intl = useIntl();
    const history = useHistory();
    const location: any = useLocation();
    const [collapsed, setCollaped] = useState(window.innerWidth < 769 ? true : false);
    const { authState, authDispatch } = useAuth();
    const { layoutState, layoutDispatch } = useLayout();
    const [cookies] = useCookies();
    const [visible, setVisible] = useState(false);
    const [logoutMessage, setLogoutMessage] = useState('MUTIPLE_LOGIN_MESSAGE');
    const [mainAccVisible, setMainAccVisible] = useState(false);
    const [mainAccMessage, setMainAccMessage] = useState('MAIN_ACCOUNT_LOGOUT');
    const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN || 'prod-scf-rt';
    const [getUserProfile, { data, error, called, refetch }] = useLazyQuery(GET_USER_PROFILE, {
        fetchPolicy: 'no-cache',
        errorPolicy: "all"
    });


    const { data: cryptoWallet } = useQuery(GET_CRYPTO_WALLET, { fetchPolicy: 'network-only' });
    const [showCryptoWalletDialog, setShowCryptoWalletDialog] = useState<any>(false);
    const [isCancelCryptoWallet, setIsCancelCryptoWallet] = useState<any>(false);
    const [remainPoint, setRemainPoint] = useState<any>(undefined)
    const [addCryptoWallet, { data: addCryptoWalletData, error: addCryptoWalletError, loading: addCryptoWalletLoading }] = useMutation(ADD_CRYPTO_WALLET);
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [cryptoWalletObject, setCryptoWalletObject] = useState<any>(null);
    const [showDXAChain, setShowDXAChain] = useState<any>(false);
    const [showDXAChainVideoGuide, setShowDXAChainVideoGuide] = useState<any>(false);
    
    useEffect(() => {
        if (authState.isLogin) {
            called && refetch ? refetch() : getUserProfile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.isLogin]);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_SOCKET_LISTENER || "");
        const sid = cookies['scf-sid'];
        socket.on(process.env.REACT_APP_SOCKET_CHANNEL_SESSION_AUTH || "", (data: any) => {
            if (includes(data, sid)) {
                setVisible(true);
                setLogoutMessage(intl.formatMessage({ id: 'MUTIPLE_LOGIN_MESSAGE' }));
            }
        });

        const point = {
            rPoint: 0,
            wPoint: 0
        }

        setRemainPoint(point);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (addCryptoWalletError) {
            setErrorNotification(intl.formatMessage({ id: "ADD_CRYPTO_WALLET_FAIL" }));
        } else if (addCryptoWalletData) {
            if (!isCancelCryptoWallet) {
                setShowDXAChain(false)
                setSuccessNotification(intl.formatMessage({ id: "ADD_CRYPTO_WALLET_SUCCESS" }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addCryptoWalletError, addCryptoWalletData]);

    useEffect(() => {
        let status = cryptoWallet?.cryptoWallet?.status
        if (status === null) {
            setShowCryptoWalletDialog(true)
            setShowDXAChain(true)
        }
        else {
            if (status === 'CANCELLED') {
                setShowDXAChain(true)
            }
            setCryptoWalletObject(cryptoWallet?.cryptoWallet)
        }
        //if cancel or agree with id

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cryptoWallet])


    const toggleSideNav = () => {
        setCollaped(!collapsed);
    };

    const showDXAChainCallback = () => {
        setShowCryptoWalletDialog(true);
    };


    //Set user profile is profile data changed
    useEffect(() => {
        if (data && !error) {
            let isMainAccountPop = cookies[`c-main-account`] ? JSON.parse(cookies[`c-main-account`]) : false;
            if (data.userProfile && data.userProfile.mainAccountId && isMainAccountPop) {
                var memberId = process.env.REACT_APP_MEMBERID_PREFIX + data.userProfile.mainAccountMemberId
                setMainAccVisible(true);
                setMainAccMessage(intl.formatMessage({ id: 'MAIN_ACCOUNT_LOGOUT' }, 
                    { memberId: `${memberId}` }
                ));
                getIsLinkPayment(memberId)
            }
            const _profile = data && data.userProfile ? data.userProfile : null;
            if (_profile) {
                console.log(_profile)
                if (_profile.pointWallets && _profile.pointWallets !== null) {
                    let rPoint = 0;
                    let wPoint = 0;
                    if (_profile.pointWallets.RPOINT && _profile.pointWallets.RPOINT !== null) {
                        if (_profile.pointWallets.RPOINT.availableBalance && _profile.pointWallets.RPOINT.availableBalance !== null) {
                            rPoint = _profile.pointWallets.RPOINT.availableBalance
                        }
                    }
                    if (_profile.pointWallets.WPOINT && _profile.pointWallets.WPOINT !== null) {
                        if (_profile.pointWallets.WPOINT.availableBalance && _profile.pointWallets.WPOINT.availableBalance !== null) {
                            wPoint = _profile.pointWallets.WPOINT.availableBalance
                        }
                    }

                    const point = {
                        rPoint,
                        wPoint
                    }

                    setRemainPoint(point);
                }
            }
            authDispatch({ type: SET_CURRENT_USER, payload: data });
        } else if (error) {
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
        }
        removeMainAccountPopUp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    useEffect(() => {
        if (layoutState.showNotification && layoutState.showNotification.message) {
            openNotificationWithIcon();
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutState.showNotification])

    useEffect(() => {
        if (!cookiesGet.get(refreshTokenKey) && authState.isLogin) {
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            history.replace('/auth', { from: location });
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookiesGet.get(refreshTokenKey)]);

    const openNotificationWithIcon = () => {
        notification[layoutState?.showNotification?.type]({
            message: layoutState?.showNotification.message,
            description: layoutState?.showNotification?.description,
        });
        layoutDispatch({ type: CLEAR_NOTIFICATION })
    };

    const getIsLinkPayment = (memberId: string) => {
        if(memberId && memberId !== null) {
            if(memberId.trim() !== '') {
                let payload = {
                    "memberId": memberId
                }

                ApiHelper.postMethod(apiConstant.url.linkMember, payload, 
                    (data: any) => {
                        console.log(data)
                    },
                    (error: any) => {
                        console.log(error)
                    }
                )
            }
        }
    }

    const handleOk = () => {
        setVisible(false);
        _logout();
    };

    const handleLogout = () => {
        setMainAccVisible(false);
        _logout();
    }

    const _logout = () => {
        clearAllTokens();
        authDispatch({ type: CLEAR_CURRENT_USER });
        authDispatch({ type: SET_LOGIN_STATUS, payload: false });
        if (client) {
            client.cache.reset();
        }
    }

    const showDXAChainVideoGuideCallback = () =>{
        setShowDXAChainVideoGuide(!showDXAChainVideoGuide)
    }

    return (<Spin tip={intl.formatMessage({ id: "SPINNER_TIP" })} spinning={layoutState.showLoading}>
        <Layout className="app-container">
            <Router>
                {authState.isLogin && 
                    <SideMenu
                        collapsed={collapsed}
                        setCollapse={toggleSideNav}
                        showDXAChain={showDXAChain}
                        showDXAChainCallback={showDXAChainCallback}
                        showDXAChainVideoGuide={showDXAChainVideoGuide}
                        showDXAChainVideoGuideCallback={showDXAChainVideoGuideCallback}
                    />}
                <Layout className="site-layout">
                    {authState.isLogin && <GeneralHeader collapsed={collapsed} toggleSideNav={toggleSideNav} />}
                    <Content
                        className="site-layout-background">
                        <Switch>
                            <Route exact path='/member'>
                                <Home />
                            </Route>

                            {routes.map((route: RouteInfo, i) => {
                                return (
                                    <Route key={i} path={`/member${route.path}`} render={({ location }) => {
                                        return (
                                            <Suspense fallback={<Home />}>
                                                <route.component routes={route.routes} />
                                            </Suspense>);
                                    }} />
                                );
                            })}

                        </Switch>
                    </Content>
                    {authState.isLogin && <GeneralFooter />}
                </Layout>
            </Router>
        </Layout>
        {showCryptoWalletDialog === true &&
            <CryptoWalletDialog
                modalVisible={{ visible: showCryptoWalletDialog, setVisible: setShowCryptoWalletDialog }}
                isCancel={{ cancel: isCancelCryptoWallet, setCancel: setIsCancelCryptoWallet }}
                submit={{ callFunction: addCryptoWallet, callError: addCryptoWalletError }}
                cryptoWalletObject={cryptoWalletObject}
                point={remainPoint}
            />
        }
        <VideoViewer
            videoNames={["USER_GUIDE_VIDEO_USE_UNIQUE_TOKEN"]}
            videoTitle={"USER_GUIDE_USE_UNIQUE_TOKEN"}
            videoBlobContainer="user-guides"
            modalVisible={{ visible: showDXAChainVideoGuide, setVisible: setShowDXAChainVideoGuide }} />
        <Modal
			visible={visible}
            closable={false}
			onOk={handleOk}
			okText={intl.formatMessage({ id: "LOGOUT" })}
            footer={[
                <Button key="btn-submit" type="primary" onClick={handleOk}>
                    {intl.formatMessage({ id: "LOGOUT" })}
                </Button>
            ]}
		>
			<p>
				{logoutMessage}
			</p>
		</Modal>
        <Modal
			visible={mainAccVisible}
            closable={true}
			onOk={handleLogout}
            onCancel={() => setMainAccVisible(false)}
            footer={[
                <Button key="btn-logout" type="primary" danger onClick={handleOk}>
                    {intl.formatMessage({ id: "LOGOUT" })}
                </Button>,
                <Button key="btn-close" type="primary" onClick={() => setMainAccVisible(false)}>
                    {intl.formatMessage({ id: "CLOSE" })}
                </Button>
            ]}
		>
			<p>
				{mainAccMessage}
			</p>
		</Modal>
    </Spin>);
}

export default AppProtected;